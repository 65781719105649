'use client';

import React, { useEffect, useRef, useState } from "react";
import ClientComponent from "./client_component";
import { Box, Button, Checkbox, Divider, Grid, Input, Menu, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { BuilderRoomType, Project, actions as builderActions } from "@/store/builderSlice";
import BuilderSelct from "@/store/builderStoreSelector";
import { BUILDER_FAILED, BUILDER_IN_PROGRESS, DimensionalViewType, ProductPlacementType, RoomViewType, SurfaceType, TRIGGER_ROOM_LAYOUT_THUMBNAILS_SAVE } from "@/utils/builder/builderConstants";
import { useSearchParams } from 'react-router-dom';
import ToggleButton from '@mui/material/ToggleButton';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveIcon from '@mui/icons-material/Save';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { saveProject as saveProjectThunk } from "@/store/builder/thunks/projectSaveThunk";
import { isFeatureAvailable } from "@/utils/builder/featureCatalog";
import { isBuilderContextReady } from "@/config/getCachedBuilderContext";
import { BUILDER_SUCCESS } from '@/utils/builder/builderConstants';
import { saveRoomLayout, saveRoomLayoutThumbnail } from "@/store/builder/thunks/roomModelsThunk";
import cssStyles from './builder.module.scss';
import ProjectSummary from "./ProjectSummary";
import BuyNowComponent from "./BuyNowComponent";
import ViewInMyRoomButton from "./ViewInMyRoomButton";
import NotificationPopup from './NotificationPopup';
import CloseIcon from '@mui/icons-material/Close';
import { useRouter } from "next/router";

const ControlsBar = () => {

    const dispatch = useDispatch();
    const projectNameRef = useRef<any | null>(null);
    const project: Project = BuilderSelct.project();
    const layoutBuilderMode: string = BuilderSelct.ui().layoutBuilderMode;
    const isCustomRoomFlow: boolean = BuilderSelct.ui().isCustomRoomFlow;
    const urlParams: { [key: string]: string } = BuilderSelct.ui().urlParams;
    const roomTypes: BuilderRoomType[] = BuilderSelct.roomTypes();
    const roomLabel: string = BuilderSelct.twoDRoomLayout().roomLabel || decodeURIComponent(urlParams.roomLabel);
    const roomType = BuilderSelct.twoDRoomLayout().roomType || urlParams.roomType;
    const captureThumbnailStatus = BuilderSelct.twoDRoomLayout().captureThumbnailStatus;
    const twoDLayoutSaveStatus = BuilderSelct.twoDRoomLayout().saveStatus;
    const thumbnailImage = BuilderSelct.twoDRoomLayout().thumbnailImage;
    const roomLabelRef = useRef<any | null>(null);
    const isRoomLabelUpdated = useRef(false);
    const canEditRoomLabel = project.roomModel.roomId == null || project.roomModel.roomId == '';
    const [showReviewLabelTooltip, setShowReviewLabelTooltip] = useState(false);
    const [showAddRoomTypeTooltip, setShowAddRoomTypeTooltip] = useState(false);
    const [roomTypeState, setRoomTypeState] = useState(roomType || '');
    const roomTypeRef = useRef<any | null>(null);
    const is2DLayoutFlow = urlParams.createRoom === 'true' || (isCustomRoomFlow && layoutBuilderMode === DimensionalViewType.TWO_D);
    const [isIOSWebKit, setIsIOSWebKit] = useState(false);
    const switchedTo2DModeRef = useRef(false);
    const editPreviewLabel = layoutBuilderMode === DimensionalViewType.TWO_D ? 'Preview' : switchedTo2DModeRef.current ? 'Edit' : 'Edit';
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    const [showViewInMyRoom, setShowViewInMyRoom] = useState(false);
    const [enableSaveButton, setEnableSaveButton] = useState(false);
    const [saveButtonTitle, setSaveButtonTitle] = useState("Save");
    const router=useRouter()
    const query=router.query
    //in quiz flow the roomId is created so we need to send the roomId in query from quiz and send it as payload to prevent from creating multiple rooms
    const roomId: string = query.roomId as string || project.roomModel.roomId||'';
    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    };
    const useWindowDimensions = () => {
        const [windowDimensions, setWindowDimensions] = useState(
            getWindowDimensions()
        );
        useEffect(() => {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }, []);
        return windowDimensions;
    };
    const windowWidth = useWindowDimensions().width;

    useEffect(() => {
        if (typeof window !== 'undefined' && (window as any).webkit) {
            setIsIOSWebKit(true);
        }
        if (urlParams.showcase == "true") {
            setShowViewInMyRoom(true);
        }
    }, []);

    useEffect(() => {
        if (projectNameRef.current) {
            (projectNameRef.current.childNodes[0] as HTMLInputElement).value = project.projectName;
        }
    }, [project.projectName]);

    useEffect(() => {
        if (roomLabelRef.current) {
            (roomLabelRef.current.childNodes[0] as HTMLInputElement).value = decodeURIComponent(roomLabel);
        }
    }, [roomLabel]);

    useEffect(() => {
        document.body.classList.add("builder-page");
        return () => {
            document.body.classList.remove("builder-pagee");
        };
    }, []);

    const changeLayoutBuilderMode = (event: any, value: string) => {
        dispatch(builderActions.updateLayoutBuilderMode({ layoutBuilderMode: value }));
    }

    const updateBuilderLayoutMode = () => {
        {!(editPreviewLabel == 'Preview') && setIsEditPopupOpen(true)};
        const targetMode = layoutBuilderMode === DimensionalViewType.TWO_D ? DimensionalViewType.THREE_D : DimensionalViewType.TWO_D;
        if (!switchedTo2DModeRef.current && targetMode === DimensionalViewType.TWO_D)
            dispatch(builderActions.updateTwoDUi({
                showLayoutInfoTooltip: true
            }));
        if (layoutBuilderMode === DimensionalViewType.THREE_D) {
            switchedTo2DModeRef.current = true;
            setEnableSaveButton(true);
        }
        if (layoutBuilderMode === DimensionalViewType.TWO_D)
            dispatch(builderActions.updateRoomLayoutThumbnailCaptureStatus({ captureThumbnailStatus: 'start' }));
        else
            dispatch(builderActions.updateLayoutBuilderMode({ layoutBuilderMode: targetMode }));
    }

    const saveProjectName = (event: any) => {
        dispatch(builderActions.updateProjectName({
            projectName: event.target.value
        }));
    }

    const updateRoomLabel = (event: any) => {
        isRoomLabelUpdated.current = true;
        setShowReviewLabelTooltip(false);
        dispatch(builderActions.updateRoomLabel({
            roomLabel: event.target.value
        }));
    }

    const handleClosePopup = () => {
        setPopupOpen(false);
      };
    
    const handleKeepEditing = () => {
        // console.log("Keep Editing clicked");
        setPopupOpen(false);

      };
    
    const handleStartDesigning = () => {
        if (urlParams.saveProject == "true" && windowWidth > 768) {
            window.location.href= `/builder?projectId=${project.projectId}`
        } else {
            window.location.href= `/projects?projectId=${project.projectId}`
        }
      };

    const editProjectName = (event: any) => {
        (projectNameRef.current.childNodes[0] as HTMLInputElement).focus();
    }

    const editRoomLabel = (event: any) => {
        (roomLabelRef.current.childNodes[0] as HTMLInputElement).focus();
    }

    const addCornerToRoom = () => {
        dispatch(builderActions.addRoomCornerVertex({
            undoable: true
        }));
    }

    const saveRoomModelAndNavigateToQuestionnairePage = async () => {
        // if (!isRoomLabelUpdated.current && (project.roomModel.roomId === '' || project.roomModel.roomId == null)) {
        //     setShowReviewLabelTooltip(true);
        //     (roomLabelRef.current.childNodes[0] as HTMLInputElement).focus();
        //     return;
        // }
        setSaveButtonTitle("Saving...");
        if (roomTypeState == null || roomTypeState === '') {
            setShowAddRoomTypeTooltip(true);
            (roomTypeRef.current.childNodes[0] as HTMLSelectElement).focus();
            return;
        }
        await dispatch(saveRoomLayout({roomId}) as any);
        

        if (urlParams.saveProject == "true") { 
            
            const designId = urlParams.designId;
            const designImage = decodeURIComponent(urlParams.designImage);
            const styleName = decodeURIComponent(urlParams.style);
            if(designId && designImage) {
                await dispatch(builderActions.updateProjectMetdata({
                    metadata: {
                        styleName: styleName,
                        designId: designId,
                        designImage: decodeURIComponent(urlParams.designImage)
                    }
                }));
            }

            await saveProjectAction();
        }
        await saveHandler();

        setSaveButtonTitle("Save");
    }

    const onRoomTypeSelection = (event: SelectChangeEvent) => {
        let newRoomLabel = undefined;
        if (roomLabel.indexOf('undefined') != -1) {
            newRoomLabel = roomLabel.replace('undefined', event.target.value);
        }
        dispatch(builderActions.updateRoomLabel({
            roomType: event.target.value,
            roomLabel: newRoomLabel
        }));
        if (showAddRoomTypeTooltip)
            setShowAddRoomTypeTooltip(false);
    };

    const resetTooltip = () => {
        if (showAddRoomTypeTooltip)
            setShowAddRoomTypeTooltip(false);
    }

    useEffect(() => {
        if (roomType && roomType != roomTypeState)
            setRoomTypeState(roomType)
    }, [roomType]);

    useEffect(() => {
        if (captureThumbnailStatus === 'end') {
            dispatch(builderActions.updateLayoutBuilderMode({ layoutBuilderMode: DimensionalViewType.THREE_D }));
            dispatch(builderActions.updateRoomLayoutThumbnailCaptureStatus({ captureThumbnailStatus: '' }));
            setEnableSaveButton(true);
        }
    }, [captureThumbnailStatus]);

    useEffect(() => {
        if (twoDLayoutSaveStatus === TRIGGER_ROOM_LAYOUT_THUMBNAILS_SAVE && layoutBuilderMode === DimensionalViewType.THREE_D && thumbnailImage != null && thumbnailImage !== '') {
            dispatch(saveRoomLayoutThumbnail({ image: thumbnailImage }) as any);
        }
    }, [twoDLayoutSaveStatus, layoutBuilderMode]);

    const builderModeLayoutToggle = <Grid item style={{ margin: '0 auto' }}>
        <ToggleButtonGroup
            value={layoutBuilderMode}
            exclusive
            onChange={changeLayoutBuilderMode}
            aria-label="text alignment"
        >
            <ToggleButton value={DimensionalViewType.TWO_D}>
                <Typography fontSize={12}>2D</Typography>
            </ToggleButton>
            <ToggleButton value={DimensionalViewType.THREE_D}>
                <Typography fontSize={12}>3D</Typography>
            </ToggleButton>
        </ToggleButtonGroup>
    </Grid>;

    console.log('debug:: ', roomTypeState, roomType);
    const saveHandler = async () => {
        await setPopupOpen(true);
    }
    
    const { height, width } = useWindowDimensions();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    function handleGenerateClick() {
        router.replace('/projects')
    }

    const get2DControls = () => {
        return <>
            {/* {!isIOSWebKit && windowWidth >= 768 && (
                <ViewInMyRoomButton forceVisible={true} buttonLabel={'Download app'} />
            )} */}
            <Grid item>
                <Tooltip
                    title={<Typography fontSize={14} color={'red'}>Review the room label before saving</Typography>}
                    arrow
                    PopperProps={{
                        style: { zIndex: 99999999, width: 200, opacity: 1, color: '#D35400' },
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [0, -15],
                                },
                            },
                        ],
                    }}
                    placement="bottom"
                    open={showReviewLabelTooltip}
                >
                    <Stack spacing={0} direction="row" sx={{ alignItems: 'center' }}>
                        <Box component={'div'}>
                            {(width > 768) && <Box component={'span'}>Name: </Box>}
                            <Input
                                defaultValue={decodeURIComponent(roomLabel)}
                                sx={{ '& input': { 
                                    textAlign: 'center', 
                                    fontSize: 16, 
                                    width: 'auto', 
                                    border: width > 768 ? 'solid' : undefined, 
                                    borderWidth: width > 768 ? 'thin' : undefined, 
                                    borderRadius: width > 768 ? '6px' : undefined 
                                }}}
                                ref={roomLabelRef}
                                //readOnly={!canEditRoomLabel}
                                onBlur={updateRoomLabel}
                            />
                        </Box>
                        {/* {canEditRoomLabel && <EditOutlinedIcon
                            fontSize="large"
                            onClick={editRoomLabel}
                            sx={{
                                color: 'black',
                                border: "solid 1px black",
                                padding: '4px',
                                borderRadius: '100%',
                                width: '1.5em',
                                height: '1.5em',
                                marginLeft: '10px',
                                cursor: 'pointer'
                            }}
                        />} */}
                    </Stack>
                </Tooltip>
            </Grid>
            {(urlParams.roomType == null || urlParams.roomType === '') && !isIOSWebKit &&
                <Grid item>
                    <Tooltip
                        title={<Typography fontSize={14} color={'red'}>Select room type before saving</Typography>}
                        arrow
                        PopperProps={{
                            style: { zIndex: 99999999, width: 200, opacity: 1, color: '#D35400' },
                            modifiers: [
                                {
                                    name: "offset",
                                    options: {
                                        offset: [0, -15],
                                    },
                                },
                            ],
                        }}
                        placement="bottom"
                        open={showAddRoomTypeTooltip}
                    >
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <Select
                                value={roomTypeState}
                                onChange={onRoomTypeSelection}
                                ref={roomTypeRef}
                                onOpen={resetTooltip}
                                displayEmpty
                            >
                                <MenuItem value="">
                                    <em><Typography fontSize={12}>Select room type</Typography></em>
                                </MenuItem>
                                {roomTypes.map(roomTypeObj =>
                                    <MenuItem key={roomTypeObj.roomType} value={roomTypeObj.roomType}>
                                        <Typography fontSize={12}>{roomTypeObj.roomTypeDisplayName}</Typography>
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Tooltip>
                </Grid>
            }
            {windowWidth >= 768 && <>
                <Grid item>
                    <Box component={'div'}
                        sx={{ marginRight: '20px' }}
                        visibility={is2DLayoutFlow ? 'visible' : 'hidden'}
                    > <Button
                        variant="contained"
                        color="primary"
                        onClick={updateBuilderLayoutMode}
                        sx={{
                            width: '137px !important',
                            marginLeft: '15px',
                            marginRight: '15px'
                        }}
                        size="small"
                        className="mobile-save-button"
                    >
                            {editPreviewLabel}
                        </Button>
                    </Box>
                </Grid>
                {((layoutBuilderMode === DimensionalViewType.TWO_D || thumbnailImage !== '') || urlParams['ftv'] !== undefined) &&
                    <Grid item>
                        <Box component={'div'}
                            sx={{ marginRight: '20px', marginLeft: '10px'}}
                            visibility={is2DLayoutFlow ? 'visible' : 'hidden'}
                        > <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                width: '137px !important'
                            }}
                            onClick={(urlParams['ftv'] !== undefined)? saveRoomModelAndNavigateToQuestionnairePage : undefined}
                            size="small"
                            className="mobile-preview-button"
                            disabled={!enableSaveButton}
                        >
                                {saveButtonTitle}
                            </Button>
                        </Box>
                    </Grid>
                    
                    
                } 
            <NotificationPopup
                open={isPopupOpen}
                onClose={handleClosePopup}
                message="Your room has been saved."
                button1='Keep Editing'
                button2='Start Designing'
                onKeepEditing={handleKeepEditing}
                onStartDesigning={handleStartDesigning}
            />

    <Box component="div"
        sx={{
            position: 'absolute', // Position it correctly
            zIndex: 99999999,
            width: width < 768 ? '90%' : '25%',
            left: width < 768 ? '5%' : '1%', // Position based on your state
            bottom: width < 768 ? '80px' : undefined, // Adjust bottom position as needed
            top: width > 768 ? '90px' : undefined, // Adjust top position as needed
            opacity: 1.0,
            display: isEditPopupOpen ? 'block' : 'none', // Show or hide based on state
          }}
      >
        <Box component="div"
          sx={{
            backgroundColor: 'white',
            paddingTop: '20px',
            paddingBottom: '20px',
            paddingLeft: '40px',
            paddingRight: '40px',
            position: 'relative',
            borderRadius: '30px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography fontSize={18} color={'#52575C'} fontFamily={'Nunito'} fontWeight={600} align="center">
          Customize your room
            <br />
          </Typography>

          <Typography fontSize={12} color={'black'} fontFamily={'Nunito'} align="center" sx={{ marginTop: '16px' }}>
          Tap a wall to add or change doors, windows, or colors. 
          Drag the corners to change dimensions and tap the floor to change the material.
          </Typography>

          {/* Close Button */}
          <Box component="div"
            sx={{
              position: 'absolute',
              width: '16px',
              height: '16px',
              top: '15px',
              right: '20px',
              cursor: 'pointer',
            }}
            onClick={() => {
                setIsEditPopupOpen(false);
            }} // Add your state logic for closing tooltip
          >
            <CloseIcon fontSize="large" color="action" />
          </Box>
        </Box>
      </Box>
            </>

            }

        </>
    }

    const saveProjectAction = async() => {
        dispatch(builderActions.updateSaveStatus({ saveStatus: BUILDER_IN_PROGRESS }));

        const resultAction = await dispatch(saveProjectThunk({}) as any);

        if (urlParams.saveProject == "true") {
            if (saveProjectThunk.fulfilled.match(resultAction)) {
                const projectIdFromSave = resultAction.payload;
                console.log("Project ID:", projectIdFromSave);
    
            } else {
                console.error("Failed to save project:", resultAction.payload);
            }
        }
    }

    const getControls = () => {
        return <>
            <Grid item style={{ marginRight: 'auto', marginLeft: '30px' }}>
                <Stack spacing={0} direction="row">
                    <Input
                        defaultValue={decodeURIComponent(project.projectName)}
                        sx={{ '& input': { textAlign: 'left', fontSize: 16, fontWeight: 'bold' } }}
                        readOnly={false}
                        ref={projectNameRef}
                        onBlur={saveProjectName}
                    />
                    {<EditOutlinedIcon //(isFeatureAvailable('editableProjectName') && !is2DLayoutFlow) && 
                        fontSize="large"
                        onClick={editProjectName}
                        style={{ color: 'black', marginTop: '5px' }}
                    />}
                    {<SaveIcon
                        fontSize="large"
                        onClick={saveProjectAction}
                        style={{ color: 'black', marginTop: '5px' }}
                    />}
                </Stack>
            </Grid>
            {<ViewInMyRoomButton forceVisible={true} />}

            {/* SM - Hiding "Gnerate Preview" button */}
            <Button
                variant="contained"                
                size="small"
                color="secondary"
                sx={{margin: '0 10px',textTransform: 'capitalize'}}
                onClick={handleClick}
            >
                Generate Preview
            </Button>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                paper: {
                    elevation: 0,
                    sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    width: '500px',
                    background: 'rgba(255,255,255,1)',
                    borderRadius: '20px',
                    mt: 3,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        left: '50%',
                        width: 10,
                        height: 10,
                        bgcolor: 'rgba(255,255,255,1)',
                        transform: 'translate(-50%, -50%) rotate(45deg)',
                        zIndex: 0,
                    },
                    },
                },
                }}
                transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            >
                
                <MenuItem sx={{textAlign: 'center', display: 'flex', flexDirection: 'column', whiteSpace: 'normal'}}>          
                <Typography sx={{fontSize: '24px', fontWeight: '600',color:'#52575C'}}>Generate Preview</Typography>
                <Typography sx={{fontSize: '16px'}}>Create a high-fidelity preview to see how your room will look in real life. This takes about</Typography>
                <Typography sx={{fontSize: '16px', fontWeight: '700'}}>10 minutes</Typography>
                <Typography sx={{fontSize: '12px'}}>Note: Editing is disabled while preview is being generated. </Typography>
                <Stack spacing={2} direction="row" sx={{margin: '20px 0'}}>
                        <Button
                            variant="contained"                
                            size="small"
                            color="secondary"
                            sx={{textTransform: 'capitalize'}}
                            onClick={handleClose}
                        
                        >
                            Keep Editing
                        </Button>
                        <Button
                            variant="contained"                
                            size="small"
                            color="primary"
                            sx={{margin: '0 10px',textTransform: 'capitalize'}}
                            onClick={handleGenerateClick}
                        >
                            Generate
                        </Button>
                    </Stack> 
                </MenuItem>
            </Menu>

            <Grid item>
                <ProjectSummary />
            </Grid >
            <BuyNowComponent />
        </>
    }


    if (!isBuilderContextReady()) return <></>

    return (<Box component={'div'}
        sx={{
            height: '65px',
            display: 'flex',
            alignItems: 'center',
            width: '100%'
        }}
    >
        <Grid container justifyContent={windowWidth >= 768 ? 'right' : 'center'} alignItems="center">
            {is2DLayoutFlow ? (get2DControls()) : (getControls())}
        </Grid>




        {/* <Box component={'div'}
            sx={{
                p: 0,
                m: 1,
                bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
                color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                border: '1px solid',
                borderColor: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                borderRadius: 2,
                height: '30px'
            }}> <Button variant="contained" onClick={saveProject} size="small">Save</Button></Box>
        <div>
            <Box component={'div'}
                sx={{
                    p: 0,
                    m: 1,
                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
                    color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    height: '30px'
                }}> <Button disabled={!canUndo} variant="contained" onClick={undo} size="small">Undo</Button></Box>
        </div>
        <div>
            <Box component={'div'}
                sx={{
                    p: 0,
                    m: 1,
                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
                    color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                    border: '1px solid',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    height: '30px'
                }}> <Button disabled={!canRedo} variant="contained" onClick={redo} size="small">Redo</Button></Box>
        </div>

        <div>
            &nbsp;&nbsp;Preview Mode :
            <Checkbox
                checked={isPreviewMode}
                onChange={togglePreviewMode}
            />
        </div>
        <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2 }} style={{ background: 'black' }} />
        <Box component={'div'} sx={{ width: 200 }}>
            <ToggleButtonGroup
                value={roomView}
                exclusive
                onChange={changeRoomView}
            >
                <ToggleButton value={RoomViewType.top} aria-label="left aligned">
                    Top View
                </ToggleButton>
                <ToggleButton value={RoomViewType.firstPersonView} aria-label="centered">
                    Front view
                </ToggleButton>
            </ToggleButtonGroup>
        </Box> */}
    </Box >)
}

export default ClientComponent(ControlsBar);