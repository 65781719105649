import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import Image from 'next/image';
import { trackItem } from "@/utils";
import cssStyles from "./cms_renderer.module.scss";
import ReactMarkdown from 'react-markdown'
import { prepareCardsForCategoriesGrid } from '@/translator';
//TODO : need to refactor the existing product_cards.tsx to replace below shop_product_cards.tsx
import generateProductCards from "@/components/products/shop_product_cards";
import CommonCarouselComponent from "@/components/carousal/common_carousel";
import GenerateCategoryCard from "@/components/products/category_cards";
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { CardData, getProductsByIds } from "@/store/productSlice";
import { prepareCardsForBuilderProductsGrid } from '@/translator';
import ReactMultiCarousel from "react-multi-carousel";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

//import jsonObj from "/Users/rajendarmunjam/workspace/cms/stage/category/gifts.json";
//import jsonObj from "/Users/rajendarmunjam/workspace/cms/stage/category/beds.json";
//import jsonObj from "/Users/rajendarmunjam/workspace/cms/stage/category/sofas.json";

interface SectionData {
    backgroundImageUrl?: string;
    buttons?: SectionData[];
    color?: string;
    content?: string | string[];
    description?: string | string[];
    footer?: SectionData;
    gridItems?: SectionData[];
    header?: SectionData;
    id?: string;
    imagePanel?: SectionData;
    imageUrl?: string;
    imageUrls?: string[];
    items?: SectionData[] | string[];
    link?: string;
    name?: string;
    newTab?: boolean;
    order?: string[];
    panels?: SectionData[];
    para?: string;
    subTitle?: string;
    text?: string | string[];
    title?: string;
    space?: string;
    trackId?: string;
    trackInfo?: {
        event?: string;
        section?: string;
    };
    preProcess?: {
        method: string;
        input: any;
    };
    type?: string;
    uiCompType?: UICompType;
}

interface UICompType {
    method?: string;
    options?: {
        align?: string;
        color?: string;
        columns?: number;
        component?: string;
        display?: string;
        gutterBottom?: string;
        height?: string;
        imageWidth?: string;
        imageHeight?: string;
        textWidth?: string;
        textHeight?: string;
        imgPosition?: string;
        minHeight?: number;
        noWrap?: boolean;
        parasCount?: number;
        shape?: string;
        size?: string;
        splitRaito?: number;
        sx?: string;
        width?: string;
        variant?: string;
        markdown?: boolean;
        grid?: string;
        spacing?: number;
        objectFit?: string;
        carouselProps?: any;
        xs?: number;
    }
    className?: string;

    styles?: React.CSSProperties;
    sectionStyles?: React.CSSProperties;
    parentStyles?: React.CSSProperties;
    containerStyles?: React.CSSProperties;
    imageContainerStyles?: React.CSSProperties;
    imageStyles?: React.CSSProperties;
}

interface PageData {
    page: string;
    title: string;
    env: string;
    order: string[];
    sections: {
        [key: string]: SectionData;
    };
}

const CMS_HOST = process.env.CMS_HOST;

export type RenderFunctionType = {
    [key: string]: (sectionData: any, props?: any, index?: number) => JSX.Element | null;
};

export function Accordion(sectionData: any): JSX.Element | null {
    console.log("Accordion...");
    const panelClassName = "accordion";

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <>
            {sectionData.title && <Title {...sectionData} />}
            {sectionData.description && <Typography variant="body1" className={cssStyles[panelClassName + "Description"]}>
                {transformDescription(sectionData.description, "", sectionData.trackId)}
            </Typography>}
            <div className={cssStyles[panelClassName + "Container"]}>
                {sectionData.items && sectionData.items.map((item: any, index: number) => (
                    <MuiAccordion key={index} expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                        sx={{ boxShadow: 'none', '&:before': { 'opacity': '1 !important' }, '&.Mui-expanded': { margin: '0px !important' } }}
                        className={cssStyles[panelClassName + "AccordionItem"]}>
                        <AccordionSummary
                            expandIcon={expanded === `panel${index}` ? <RemoveIcon /> : <AddIcon />}
                            aria-controls={`panel${index}bh-content`}
                            id={`panel${index}bh-header`}
                            className={cssStyles["AccordionSummary"]}
                            sx={{
                                minHeight: 'initial !important',
                                '.Mui-expanded': {
                                    margin: '10px 0 !important'
                                }
                            }}
                        >
                            <strong>{item.title}</strong>
                        </AccordionSummary>
                        <AccordionDetails className={cssStyles["AccordionDetails"]}>
                            {transformDescription(item.description, undefined, item.trackId)}
                        </AccordionDetails>
                    </MuiAccordion>
                ))}
            </div>
        </>
    );
}

export function Carousel(sectionData: any, props: any): JSX.Element | null {
    console.log("Carousel...");
    const { vendorName, userInfo, favorites } = props;

    const panelClassName = "carousel";
    return (
        <>
            {sectionData.title && <Title {...sectionData} />}
            <Grid container className={cssStyles[panelClassName + "Container"]}>
                <CommonCarouselComponent fullWidth={true} type="scrollable"
                    cards={sectionData.cards.map((productCard: any) => (
                        <Grid key={productCard.name} item>{productCard}</Grid>
                    ))} />
            </Grid>
        </>
    );
}

export function CategoriesCarousel(sectionData: any, props: any): JSX.Element | null {
    console.log("CategoriesCarousel...");
    const { vendorName, userInfo, favorites } = props;
    const panelClassName = "carousel";
    const cards = GenerateCategoryCard(
        prepareCardsForCategoriesGrid(sectionData.items as CardData[], getBrandPathEntry(vendorName), vendorName),
        handleProductSelection,
        (sectionData.uiCompType?.options?.height || "320"),
        (sectionData.uiCompType?.options?.width || "320"),
        sectionData.trackId || "CategoryCarouselCards",
    );
    return Carousel({ ...sectionData, cards }, props);
}

export function CirclerImageButton(sectionData: any, props: any, index: number = 1): JSX.Element | null {
    console.log("CirclerImageButton...");
    const baseClassName = "imgButtonCircular";
    const styles = sectionData.uiCompType?.styles || {};
    const title = sectionData.title || sectionData.name || sectionData.text;
    return (
        <div key={(sectionData.trackId || '') + index} className={cssStyles[baseClassName]} style={styles}>
            <div
                className={cssStyles[`${baseClassName}__outerCircle`]}
                onClick={(e) => { trackItem(sectionData.trackInfo || {}); }}
            >
                <div
                    className={cssStyles[`${baseClassName}__innerCircle`]}
                    style={{
                        height: sectionData.uiCompType?.options?.height || 200,
                        width: sectionData.uiCompType?.options?.width || 200,
                    }}
                >
                    <div
                        className={cssStyles[`${baseClassName}__circle`]}
                        style={{ backgroundImage: `url(${sectionData.imageUrl})` }}
                    >
                    </div>
                </div>
            </div>

            {title && renderFunctions.CustText({
                text: title,
                uiCompType: {
                    ...sectionData.uiCompType,
                    options: {
                        markdown: true,
                        ...sectionData.uiCompType?.options
                    },
                    styles: {
                        ...sectionData.uiCompType?.styles,
                    }
                }
            }, props)
            }

        </div>
    );
}

export function extractStylesFromUrl(urlStr: string) {
    try {

        const url = new URL(urlStr);
        const params = new URLSearchParams(url?.hash?.substring(1));
        const stylesFromUrl = Object.fromEntries(params.entries());
        url.hash = '';
        return { 'urlStr': url.toString(), 'stylesFromUrl': stylesFromUrl };
    } catch (error) {
        console.info("invalid URL:", error);
        return { 'urlStr': urlStr, 'stylesFromUrl': {} };
    }
}

export function ContentOnImage(sectionData: any, props: any): JSX.Element | null {
    console.log("ContentOnImage...");
    const panelClassName = "contentOnImage";
    return (
        <div className={cssStyles[panelClassName + "Container"]} style={sectionData.uiCompType?.styles} >
            {sectionData.content && <div className={cssStyles[panelClassName + "Content"]}>
                {Panel(sectionData.content as any, props)}
            </div>}
            {sectionData.backgroundImageUrl &&
                <Image alt={sectionData.title || ''} src={sectionData.backgroundImageUrl} layout="fill" objectFit="cover" />
            }
        </div>
    );
}


export function CustText(sectionData: any): JSX.Element | null {
    console.log("CustText...");
    const styles = sectionData.uiCompType?.styles || {};

/*     const renderTypography = () => (
        <Typography
            {...(sectionData.uiCompType?.options?.variant && { variant: sectionData.uiCompType?.options?.variant })}
            {...(sectionData.uiCompType?.options?.component && { component: sectionData.uiCompType?.options?.component })}
            {...(sectionData.uiCompType?.options?.align && { align: sectionData.uiCompType?.options?.align })}
            {...(sectionData.uiCompType?.options?.color && { color: sectionData.uiCompType?.options?.color })}
            {...(sectionData.uiCompType?.options?.display && { display: sectionData.uiCompType?.options?.display })}
            {...(sectionData.uiCompType?.options?.gutterBottom && { gutterBottom: sectionData.uiCompType?.options?.gutterBottom })}
            {...(sectionData.uiCompType?.options?.noWrap && { noWrap: sectionData.uiCompType?.options?.noWrap })}
            {...(sectionData.uiCompType?.options?.sx && { sx: sectionData.uiCompType?.options?.sx })}
            {...(sectionData.uiCompType?.className && { className: cssStyles[sectionData.uiCompType.className] })}
            style={styles}
        >
            {transformDescription(sectionData.text || '', "", (sectionData.trackId || ''), styles)}
        </Typography>
    ); */

    return (
        <>
            {sectionData.text &&              
            transformDescription(sectionData.text || '', 
                sectionData.uiCompType.className, 
                (sectionData.trackId || ''), 
                styles, 
                sectionData.uiCompType?.options?.component || sectionData.uiCompType?.options?.variant)
            }
        </>
    );
}

const parseDimension = (dimension?: string | number | undefined): number | undefined => {
    if (typeof dimension === 'string') {
        return parseInt(dimension.replace('px', ''), 10);
    }
    return dimension;
};

export function CustButton(sectionData: any, index: number = 1): JSX.Element | null {
    console.log("CustButton...");
    const panelClassName = "custButton";
    const button = getButton(sectionData, index);

    return sectionData.uiCompType?.parentStyles ? (
        <div style={{ ...sectionData.uiCompType?.parentStyles }}>
            {button}
        </div>
    ) : (
        <span>
            {button}
        </span>
    );
}
export function CustImage(sectionData: any, index: number = 1): JSX.Element | null {
    console.log("CustImage...");
    const { stylesFromUrl, urlStr } = extractStylesFromUrl(sectionData.imageUrl as string);
    const imageStyles = { ...sectionData.uiCompType?.styles, ...stylesFromUrl };
    const imageContainerStyles = { ...sectionData.uiCompType?.containerStyles, ...sectionData.uiCompType?.parentStyles };

    // moving imageStyles to ContainerStyles when the contianerStyles does not have height or width
    if (!imageContainerStyles.height && !imageContainerStyles.width) {
        if (imageStyles.width) {
            imageContainerStyles.width = `${parseDimension(imageStyles.width as string | number)}px`;
            delete imageStyles.width;
        }

        if (imageStyles.height) {
            imageContainerStyles.height = `${parseDimension(imageStyles.height as string | number)}px`;
            delete imageStyles.height;
            if (!imageContainerStyles.width) {
                imageContainerStyles.width = '100%';
            }
        }
    }

    const image = (
        (imageStyles.width && imageStyles.height) ? (
            <Image
                alt={sectionData.title || sectionData.imageUrl || ''}
                src={urlStr as string}
                width={parseDimension(imageStyles.width as string)}
                height={parseDimension(imageStyles.height as string)}
                style={imageStyles}
                className={cssStyles.image}
            />
        ) : (
            <Image
                alt={sectionData.title || urlStr || ''}
                src={urlStr as string}
                fill={true}
                layout="fill"
                objectFit={sectionData.uiCompType?.options?.objectFit || imageStyles.objectFit as string || "cover"}
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                quality={75}
                style={imageStyles}
                onError={(e) => console.error("Failed to load Image : " + urlStr)}
                className={cssStyles.image}
            />
        )
    );

    return (
        <div>
            {sectionData.link ? (
                <a href={sectionData.link} target={sectionData.newTab ? "_blank" : "_self"}
                    onClick={() => { trackItem(sectionData.trackInfo || {}); }}
                >
                    <div key={sectionData.trackId as string + index} className={cssStyles.imageContainer} style={{ position: 'relative', ...imageContainerStyles }}>
                        {image}
                    </div>
                </a>
            ) : (
                <div key={(sectionData.trackId || '') + index} className={cssStyles.imageContainer} style={{ position: 'relative', ...imageContainerStyles }}>
                    {image}
                </div>
            )}
        </div>
    );
}



export const ExpandableContent: React.FC<{ maxHeight: number; children: React.ReactNode }> = ({ children, maxHeight }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpand = () => {
        setExpanded(!expanded);
        if (expanded && wrapperRef.current) {
            //wrapperRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const [showExpanderLink, setShowExpanderLink] = useState(false);
    const contentRef = React.useRef<HTMLDivElement>(null);
    const wrapperRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (contentRef.current) {
            setShowExpanderLink(contentRef.current.scrollHeight > maxHeight);
        }
    }, [maxHeight, children]);

    return (
        <div style={{ border: '0px solid red' }} id="expanderWrapper" ref={wrapperRef}>
            <div
                id="expanderContent"
                ref={contentRef}
                style={{
                    maxHeight: expanded ? `${contentRef.current?.scrollHeight}px` : `${maxHeight}px`,
                    overflow: 'hidden',
                    //border: '1px solid green',
                    transition: 'max-height 0.5s ease-in-out',
                }}
            >
                {children}
            </div>
            {showExpanderLink && (
                <div
                    id="expanderLink"
                    style={{
                        textAlign: 'right',
                        //marginTop: '-10px',
                        //borderTop: '1px solid black',
                    }}
                >
                    <a
                        onClick={toggleExpand}
                        style={{
                            cursor: 'pointer',
                            color: 'black',
                            fontWeight: 'bold',
                            textDecoration: 'none',
                        }}
                    >
                        {expanded ? '...Less -' : '... More +'}
                    </a>
                </div>
            )}
        </div>
    );
};


const getButton = (sectionData: any, index: number) => (
    <Button
        key={(sectionData.trackId || 'defaultTrackId') + index}
        id={sectionData.trackId}
        color={sectionData.color || sectionData.uiCompType?.options?.color || "primary"}
        href={sectionData.link}
        target={sectionData.newTab ? "_blank" : "_self"}
        onClick={() => {
            trackItem(sectionData.trackInfo || {});
            if (sectionData.preProcess?.method) {
                // @ts-ignore
                if (typeof window[sectionData.preProcess.method] === 'function') {
                    // @ts-ignore
                    window[sectionData.preProcess.method](sectionData.preProcess.input);
                }
            }
        }}
        style={{ ...sectionData.uiCompType?.styles }}
        size={sectionData.uiCompType?.options?.size || "small"}
    >
        {sectionData.title}
    </Button>
);

const handleProductSelection = (cardIndex: any, event?: any, card?: any, sectionName?: string) => {
    console.log('Selected cardIndex: ' + cardIndex + "cardId : " + JSON.stringify(card) + "sectionName : " + sectionName);
    trackItem({
        id: card.id,
        event: sectionName + 'Click',
        name: card.name,
        section: sectionName
    });
}


export function ImageButton(item: any, props: any, index: number = 1): JSX.Element | null {
    console.log("ImageButton...");
    return item.link
        ? <a key={item.trackId + index} href={item.link}
            target={item.newTab ? "_blank" : "_self"}
            onClick={() => { trackItem(item.trackInfo) }}
        >
            {CirclerImageButton(item, props, index)}
        </a>
        : CirclerImageButton(item, props, index);
}

export function ItemsGrid(sectionData: any, props: any): JSX.Element | null {
    console.log("ItemsGrid...");
    const styles = { ...sectionData.uiCompType?.styles };
    delete sectionData.uiCompType?.styles;
    const items = sectionData.items;
    return (
        <>
            {sectionData.uiCompType?.options?.grid === 'flex' ? (
                <div style={{ display: 'flex', flexWrap: 'wrap', ...styles }} className={cssStyles.gridContainer}>
                    {items?.map((item: any, index: number) => (
                        <div
                            key={index}
                            style={{
                                flex: `1 0 ${100 / (sectionData.uiCompType?.options?.columns || 4)}%`,
                                boxSizing: 'border-box',
                                padding: sectionData.uiCompType?.options?.spacing || '8px'
                            }}
                        >
                            {renderSection(item.trackId, item, props)}
                        </div>
                    ))}
                </div>
            ) : sectionData.uiCompType?.options?.grid === 'center' ? (
                <Grid container justifyContent="center" spacing={0} className={cssStyles.gridContainer} style={styles}>
                    {items?.map((itemObj: any, index: number) => (
                        <Grid item className={cssStyles.gridItem}
                            style={{ ...itemObj.uiCompType?.styles }}
                            xs={sectionData.uiCompType?.options?.xs || 'auto'}
                            key={index}>
                            {renderSection(itemObj.trackId, itemObj, props)}
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Grid container spacing={sectionData.uiCompType?.options?.spacing || 2} className={cssStyles.gridContainer} style={styles}>
                    {items?.map((itemObj: any, index: number) => (
                        <Grid item className={cssStyles.gridItem}
                            style={{ ...itemObj.uiCompType?.styles }}
                            xs={sectionData.uiCompType?.options?.xs || 12}
                            md={12 / items.length}
                            key={index}>
                            {renderSection(itemObj.trackId, itemObj, props)}
                        </Grid>
                    ))}
                </Grid>
            )}
        </>
    );
}

export function MarkdownTextPanel(sectionData: any): JSX.Element | null {
    console.log("MarkdownTextPanel...");
    const trackId = sectionData.trackId || "markdownTextPanel";
    const items = sectionData.items as string[] | string;
    const { parasCount, minHeight } = sectionData.uiCompType?.options || {};
    const styles = sectionData.uiCompType?.styles || {};

    const panelClassName = trackId ? trackId + "_sectionDescription" : "sectionDescription";
    const [showMore, setShowMore] = React.useState(false);

    if (typeof items === "object") {
        if (minHeight) {
            return (
                <ExpandableContent maxHeight={minHeight}>
                    {items.map((descItem: string, index: number) => transformTextToHtml(descItem, panelClassName, trackId + index, styles))}
                </ExpandableContent>
            );
        } else if (parasCount) {
            const showMoreParaCount = isNaN(parasCount) ? 1 : parasCount;
            const firstPart = items.slice(0, showMoreParaCount);
            const secondPart = items.slice(showMoreParaCount);

            return (
                <>
                    <div style={{ ...styles }}>
                        {firstPart.map((descItem: string, index: number) => {
                            let transformedText = transformTextToHtml(descItem, panelClassName, trackId + index);
                            if (!showMore && index + 1 === firstPart.length) {
                                transformedText = React.createElement(
                                    React.Fragment,
                                    {},
                                    (transformedText as React.ReactElement).props.children,
                                    <a key={`showMore-${trackId}`} href="#" onClick={(e) => { e.preventDefault(); setShowMore(true); }}
                                        style={{ fontWeight: 'bold', textDecoration: 'none', marginLeft: '5px', color: 'black' }}>
                                        ...More
                                    </a>
                                );
                            }
                            return transformedText;
                        })}
                    </div>
                    {showMore && (
                        <div style={{ ...styles }}>
                            {secondPart.map((descItem: string, index: number) => {
                                let transformedText = transformTextToHtml(descItem, panelClassName, trackId + showMoreParaCount + index);
                                if (index + 1 === secondPart.length) {
                                    transformedText = React.createElement(
                                        React.Fragment,
                                        {},
                                        (transformedText as React.ReactElement).props.children,
                                        <a key={`showLess-${trackId}`} href="#" onClick={(e) => { e.preventDefault(); setShowMore(false); }}
                                            style={{ fontWeight: 'bold', textDecoration: 'none', marginLeft: '5px', color: 'black' }}>
                                            ...Less
                                        </a>
                                    );
                                }
                                return transformedText;
                            })}
                        </div>
                    )}
                </>
            );
        } else {
            return (
                <>
                    {items.map((descItem: string, index: number) => (
                        transformTextToHtml(descItem, panelClassName, trackId + index, styles)
                    ))}
                </>
            );
        }
    } else {
        return transformTextToHtml(items, panelClassName, trackId, styles);
    }
}


function extractKeyStyles(styleParts: string[]): React.CSSProperties {
    const keyStyles = styleParts.reduce((acc, curr) => {
        if (curr.match(/^(left|center|right|justify|start|end)$/)) {
            acc.textAlign = curr as React.CSSProperties['textAlign'];
        } else if (curr.match(/^(small|medium|large|\d+px|\d+em|\d+rem)$/)) {
            acc.fontSize = curr;
        } else if (curr.match(/^(#(?:[0-9a-fA-F]{3}){1,2}|red|blue|green|black|white)$/)) {
            acc.color = curr;
        } else if (curr.match(/^(bold|normal|light|\d{3})$/)) {
            acc.fontWeight = curr as React.CSSProperties['fontWeight'];
        }
        return acc;
    }, {} as React.CSSProperties);
    return keyStyles;
}

const renderText = (text: string | string[], className: string, 
        uiCompType: any, props: any, variant?: string, extractedStyles?: React.CSSProperties) => {
   
    if(!text) return;
    return renderFunctions.CustText({
        text,
        uiCompType: {
            className,
            ...uiCompType,
            options: {
                variant,
                ...uiCompType?.options,
            },
            styles: {
                ...uiCompType?.styles,
                ...extractedStyles,
            }
        }
    }, props);
};
export function Panel(sectionData: any, props?: any): JSX.Element | null {
    console.log("Panel...");
    const panelClassName = "panel";
    const styles = { ...sectionData.uiCompType?.styles };
    delete sectionData.uiCompType?.styles;

    return (
        <div id={sectionData.trackId || 'panel'} className={cssStyles[panelClassName]} style={styles}>
            {Object.keys(sectionData).map((key: string) => {
                if (key === 'uiCompType') return null;
                const [actualKey, ...styleParts] = key.split('-');
                const extracedStyles = extractKeyStyles(styleParts);
                switch (key) {
                    case 'space':
                        return <div key={key} style={{ height: sectionData.space }} />;
                    case 'h1':
                        return renderText(sectionData[key], 'title', sectionData.uiCompType, props, 'h1', extracedStyles);
                    case 'title':
                        return renderText(sectionData[key], 'title', sectionData.uiCompType, props, 'h2', extracedStyles);
                    case 'subTitle':
                        return renderText(sectionData[key], 'subTitle', sectionData.uiCompType, props, 'h3', extracedStyles);
                    case 'text':
                        return renderText(sectionData.text, 'text', sectionData.uiCompType, props, 'string', extracedStyles);
                    case 'description':
                        return renderText(sectionData.description, 'description', sectionData.uiCompType, props, undefined, extracedStyles);

                    case 'imageUrl':  /* when a simple imageUrl string is giving with some height af # */
                        return renderFunctions.CustImage({
                            imageUrl: sectionData.imageUrl,
                            link: sectionData.link
                        }, props);

                    case 'imagePanel':
                        return sectionData.imagePanel &&
                            renderFunctions.CustImage({
                                ...sectionData.imagePanel,
                                uiCompType: {
                                    ...sectionData.imagePanel.uiCompType,
                                    styles: sectionData.imagePanel.uiCompType?.styles,
                                    parentStyles: sectionData.imagePanel.uiCompType?.parentStyles
                                }
                            }, props);
                    case 'button':
                        return sectionData.button && (
                            <div key={key} className={cssStyles[panelClassName + "Button"]} style={sectionData.button.uiCompType?.parentStyles}>
                                {getButton(sectionData.button, 0)}
                            </div>
                        );
                    case 'buttons': // TODO : need to replace with ItemsGrid
                        const buttons = sectionData.buttons as any[];
                        return buttons.length > 0 && (
                            <div key={key} className={cssStyles[panelClassName + "Buttons"]} style={buttons[0].uiCompType?.parentStyles}>
                                {buttons.map((button: any, index: number) => (
                                    getButton(button, index)
                                ))}
                            </div>
                        );
                    case 'panels':
                        return sectionData.panels?.map((item: any, index: number) => (
                            renderSection(item.trackId, item, props)
                        ));
                    case 'rows':
                        return sectionData.panels?.map((item: any, index: number) => (
                            renderSection(item.trackId, item, props)
                        ));
                    case 'items':
                        return sectionData.items?.map((item: any, index: number) => (
                            renderSection(item.trackId, item, props)
                        ));
                    case 'gridItems':
                        const uiCompType = {
                            options: {
                                splitRaito: sectionData.uiCompType?.options?.splitRaito,
                                grid: sectionData.uiCompType?.options?.grid || 'spread',
                                spacing: sectionData.uiCompType?.options?.spacing || 2
                            },
                            styles: sectionData.uiCompType?.parentStyles
                        }
                        const gridSectionData = {
                            items: sectionData.gridItems,
                            uiCompType: uiCompType
                        };
                        return ItemsGrid(gridSectionData, props);
                    default:
                        const otherObject = (sectionData as any)[key];
                        if (typeof otherObject === 'object') {
                            return renderSection(otherObject.trackId, otherObject, props);
                        }
                        return null;
                }
            })}
        </div>
    );
}

export function ProductsCarousel(sectionData: any, props: any): JSX.Element | null {
    console.log("ProductsCarousel...");
    const { vendorName, userInfo, favorites, setFavorites } = props;
    const panelClassName = "carousel";

    const cards = generateProductCards(
        prepareCardsForBuilderProductsGrid(sectionData.items as CardData[], getBrandPathEntry(vendorName)),
        handleProductSelection,
        (sectionData.uiCompType?.options?.height || "320px"),
        'hiddenGemsProducts',
        undefined,
        undefined,
        undefined,
        favorites,
        setFavorites,
        userInfo?.cId
    );

    return Carousel({ ...sectionData, cards }, props);
}

/* export function SideImagePanel(sectionData: any, props?: any): JSX.Element | null {
    console.log("SideImagePanel...");
    const orderClass = sectionData.uiCompType?.options?.imgPosition == "right" ? cssStyles.swapOrder : '';
    const gridSplitRatio = sectionData.uiCompType?.options?.splitRaito || 6;
    const panelClassName = "sideImagePanel";
    const hasTextToShow = sectionData.title || sectionData.description || sectionData.buttons;
    const { urlStr, stylesFromUrl } = extractStylesFromUrl(sectionData.imageUrl as string);
    const imageStyles: React.CSSProperties = { ...stylesFromUrl };
    if (sectionData.uiCompType?.options?.imageHeight) {
        imageStyles.height = sectionData.uiCompType?.options?.imageHeight;
    }
    if (sectionData.uiCompType?.options?.imageWidth) {
        imageStyles.width = sectionData.uiCompType?.options?.imageWidth;
    }

    const textStyles: React.CSSProperties = {};
    if (sectionData.uiCompType?.options?.textHeight) {
        textStyles.height = sectionData.uiCompType?.options?.textHeight;
    }
    if (sectionData.uiCompType?.options?.textWidth) {
        textStyles.width = sectionData.uiCompType?.options?.textWidth;
    }
    const getMaxHeight = (height1: string | number | undefined, height2: string | number | undefined): number => {
        const parseHeight = (height: string | number | undefined): number => {
            if (typeof height === 'string') {
                return parseInt(height.replace('px', ''), 10);
            }
            return height || 0;
        };

        return Math.max(parseHeight(height1), parseHeight(height2));
    };

    const styles = {
        ...sectionData.uiCompType?.styles,
        height: sectionData.uiCompType?.styles?.height || `${getMaxHeight(imageStyles.height, textStyles.height)}px`
    };

    if (sectionData.imagePanel && !sectionData.imagePanel.uiCompType?.styles?.height) {
        sectionData.imagePanel.uiCompType = {
            styles: {
                ...sectionData.imagePanel?.uiCompType?.styles,
                height: styles.height
            }
        }
    }
    // separating imagePanel and imageUrl as these will be handled separately in the grid
    const { imagePanel, imageUrl, ...restSectionData } = sectionData;
    const imgSectionData = { imagePanel, imageUrl } as any;


    return (
        <Grid container spacing={2} id={sectionData.trackId || "sideImagePanel"}
            className={`${cssStyles[panelClassName + "Container"]} ${orderClass}`} >
            {imagePanel &&
                <Grid item xs={12} md={hasTextToShow ? gridSplitRatio : 12} className={cssStyles[panelClassName + "ImageGridItem"]}>
                    <div className={cssStyles[panelClassName + "Image"]} style={imageStyles}>
                        {renderSection(sectionData.imagePanel?.trackId as string, sectionData.imagePanel as any, props)}
                    </div>
                </Grid>
            }
            {imageUrl &&
                <Grid item xs={12} md={hasTextToShow ? gridSplitRatio : 12} className={cssStyles[panelClassName + "ImageGridItem"]}>
                    <div className={cssStyles[panelClassName + "Image"]} style={imageStyles}>
                        <Image alt={sectionData.title || sectionData.imageUrl || ''} src={sectionData.imageUrl || ''} layout="fill" objectFit="cover" />
                    </div>
                </Grid>
            }
            {Object.keys(imgSectionData).length > 0 &&
                <Grid item xs={12} md={hasTextToShow ? gridSplitRatio : 12} className={cssStyles[panelClassName + "ImageGridItem"]}>
                    {Panel(imgSectionData, props)}
                </Grid>}

            {Object.keys(restSectionData).length > 0 &&
                <Grid item xs={12} md={hasTextToShow ? (12 - gridSplitRatio) : 12} className={cssStyles[panelClassName + "Text"]} style={textStyles}>
                    {Panel(restSectionData, props)}
                </Grid>
            }
        </Grid>
    );
} */

export function Slider(sectionData: any, props: any): JSX.Element | null {

    console.log("Slider...");
    const styles = sectionData.uiCompType?.containerStyles || { 'height': '500px', 'width': "100%" };
    const imageContainerStyles = { ...sectionData.uiCompType?.imageContainerStyles, height: sectionData.uiCompType?.imageContainerStyles?.height || sectionData.uiCompType?.containerStyles?.height };
    const options = sectionData.uiCompType?.options || {};
    const CustomRight = ({ onClick }: any) => (
        <button className={`${cssStyles["react-multiple-carousel__arrow"]} ${cssStyles["react-multiple-carousel__arrow--right"]}`} onClick={onClick}>
            <NavigateNextIcon />
        </button>
    );
    const CustomLeft = ({ onClick }: any) => (
        <button className={`${cssStyles["react-multiple-carousel__arrow"]} ${cssStyles["react-multiple-carousel__arrow--left"]}`} onClick={onClick}>
            <NavigateBeforeIcon />
        </button>
    );
    const panelClassName = "slider";
    return (
        <div style={styles}>
            <ReactMultiCarousel
                afterChange={(previousSlide, { currentSlide }) => {
                    console.log("currentSlide", currentSlide);
                }}
                responsive={{
                    desktop: {
                        breakpoint: { max: 3000, min: 1024 },
                        items: 1,
                        slidesToSlide: 1,
                        partialVisibilityGutter: 80,
                        ...options.carouselProps?.responsiveCust?.desktop
                    },
                    tablet: {
                        breakpoint: { max: 1024, min: 464 },
                        items: 1,
                        slidesToSlide: 1,
                        partialVisibilityGutter: 80,
                        ...options.carouselProps?.responsiveCust?.tablet
                    },
                    mobile: {
                        breakpoint: { max: 464, min: 0 },
                        items: 1,
                        slidesToSlide: 1,
                        partialVisibilityGutter: 60,
                        ...options.carouselProps?.responsiveCust?.mobile
                    },
                }}
                partialVisible={false}
                ssr={true}
                showDots={true}
                autoPlay={false}
                dotListClass={cssStyles["designer-carousel-dot-list"]}
                swipeable={true}
                keyBoardControl={true}
                itemClass="carousel-item-padding-40-px"
                customRightArrow={<CustomRight />}
                customLeftArrow={<CustomLeft />}
                {...options.carouselProps}
            >
                {sectionData.imageUrls?.map((imageUrl: any, i: number) => {
                    return (<div key={(sectionData.trackId || '') + i} className={cssStyles[panelClassName + "Image"]} style={imageContainerStyles}>
                        <Image alt={(sectionData.trackId || '') + i} src={imageUrl} layout="fill" objectFit="cover" style={sectionData.uiCompType?.imageStyles} />
                    </div>)
                })}
                {sectionData.items?.map((eachSlide: any, i: number) => renderSection(eachSlide.name, eachSlide, props))}
            </ReactMultiCarousel>
        </div>
    );
}

export function SubTitle(sectionData: any): JSX.Element | null {
    return renderText(sectionData.subTitle , "subTitle", sectionData.uiCompType, {} , 'h3') || null;
}

export function Title(sectionData: any): JSX.Element | null {
    return renderText(sectionData.title, "title", sectionData.uiCompType, {} , 'h2') || null;
}

export function transformDescription(items: string[] | string, panelClassName?: string, trackId?: any, style?: React.CSSProperties, elemType?: string): React.ReactNode {
    if (Array.isArray(items)) {
        return items.map((descItem: string, index: number) => (
            transformTextToHtml(descItem, panelClassName, trackId + index, style, elemType)
        ));
    } else if (typeof items === 'string') {
        return transformTextToHtml(items, panelClassName, trackId, style, elemType);
    } else {
        console.error('Invalid items type:', typeof items);
        return null;
    }
}
export const transformTextToHtml = (text: string, panelClassName?: string, id?: string, style?: React.CSSProperties, elemType: string = 'p') => {

    if (!text) {
        return null;
    }

    if (!panelClassName) {
        panelClassName = "";
    }

    let elemTypeKey: keyof JSX.IntrinsicElements = elemType as keyof JSX.IntrinsicElements;
    
    function transformMarkdown(text: string, classNameStr: string, elemTypeKey: keyof JSX.IntrinsicElements, id?: string, style?: React.CSSProperties) {

        return (
            <ReactMarkdown components={{
            p: ({ node, ...props }) => {
                return React.createElement(
                elemTypeKey,
                { id, className: cssStyles[classNameStr] || classNameStr, style: { ...style, margin: '2px'} },
                props.children
                );
            },
            a: ({ node, ...props }) => <a key={id || 'link'} 
                className={cssStyles[classNameStr] || classNameStr} 
                style={{ color: 'black', textDecoration: 'none', ...style }} 
                {...props} />
            }}>{text}</ReactMarkdown>
        );
    }

    let styleMatch = text.match(/\(STYLE=({.*?})\)(.*?)\(\/STYLE\)/i);
    if (styleMatch) {
        const styleObject = JSON.parse(styleMatch[1].replace(/'/g, '"'));
        return transformMarkdown(styleMatch[2], panelClassName, elemTypeKey, id, { ...style, ...styleObject });
    }

    styleMatch = text.match(/<STYLE=({.*?})>(.*?)<\/STYLE>/i);
    if (styleMatch) {
        const styleObject = JSON.parse(styleMatch[1].replace(/'/g, '"'));
        return transformMarkdown(styleMatch[2], panelClassName, elemTypeKey, id, { ...style, ...styleObject });
    }

    if (text.match(/^<SMALL>/i)) {
        return transformMarkdown(text.replace(/<SMALL>/i, '').replace(/<\/SMALL>/i, ''), panelClassName + "SmallText", elemTypeKey, id, style)
    } else if (text.match(/^<LARGE>/i)) {
        return transformMarkdown(text.replace(/<LARGE>/i, '').replace(/<\/LARGE>/i, ''), panelClassName + "LargeText", elemTypeKey, id, style)
    } else if (text.match(/^<CENTER>/i)) {
        return transformMarkdown(text.replace(/<CENTER>/i, '').replace(/<\/CENTER>/i, ''), panelClassName + "CenterText", elemTypeKey, id, style);
    } else if (text.match(/^<BOLD>/i)) {
        return transformMarkdown(text.replace(/<BOLD>/i, '').replace(/<\/BOLD>/i, ''), panelClassName + "BoldText", elemTypeKey, id, style);
    } else if (text.match(/^ELINE$/i) || text.trim() === "") {
        return <div key={id}>&nbsp;</div>;
    } else {
        return transformMarkdown(text, panelClassName, elemTypeKey, id, style);
    }
}

const generateProductCardsData2 = (data: any, vendorName: string) => {

    return data?.products?.map((product: any) => {
        let pdpUrl = getBrandPathEntry(vendorName) + `/product/${product.productId}`;
        let roomTypeParam = product.roomType ? '&roomType=' + product.roomType : '';
        let productTypeParam = product.productType ? '&productType=' + product.productType : '';
        let viewInYourRoomLink = `/builder?productId=${product.productId}&showcase=true&auth=false${roomTypeParam}${productTypeParam}`;
        return {
            name: product.productName,
            id: product.productId,
            image: product.productImage,
            model3dUrl: product.model3dUrl,
            pdpUrl: pdpUrl,
            viewMoreUrl: "",
            price: product.price,
            comparePrice: product.comparePrice,
            viewInYourRoomLink: viewInYourRoomLink,
            otherSizes: product.otherSizes,
            otherColors: product.otherColors,
            // otherFabrics: product.otherFabrics,
            // otherConfigs: product.otherConfigs,
            // configurationIcon: product.configurationIcon,
            priceRange: product.priceRange,
            expiresOn: product.expiresOn ? `Ends : ${new Date(product.expiresOn).toLocaleDateString()}` : 'While supplies last.',
            isBestSeller: product.isBestSeller
        };
    });
}

export const  CMS_HOST_URL = 'https://d2ffb7z1ailwvo.cloudfront.net/'

export const getCmsData = async (vendorName: string, cmsRelUrl: string, env: string) => {
    let cmsData: {
        title: string;
        description: string;
        order?: string[];
        sections?: { [s: string]: any; };
        list?: Array<any>;
        items?: Array<any>;
        uiCompType?: {
            method: string;
        };
    } = {
        sections: {},
        list: [],
        title: "",
        description: "",
        order: [],
        items: []
    };
    try {
        let cmsDataPromise = await fetch(`${CMS_HOST_URL}${cmsRelUrl}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        cmsData = await cmsDataPromise.json();
        //cmsData = jsonObj;
        type PreprocessorsType = {
            [key: string]: (productIds: string[]) => Promise<any>;
        };

        const preprocessors: PreprocessorsType = {
            getProductDetails: async (productIds: string[]) => {
                let productsDataPromise = await getProductsByIds({ productIds: productIds, env: env, brand: vendorName });
                console.log('getProductDetails: productsDataPromise', productsDataPromise);
                return productsDataPromise;
            }
        };

        const processSections = async () => {
            try {
                const sectionPromises = Object.values(cmsData.sections || {}).map(async (section: any) => {
                    try {
                        if (section.preprocess && section.preprocess.method && section.preprocess.input) {
                            const data = await preprocessors[section.preprocess.method](section.preprocess.input);
                            console.log('Processed data:', data);
                            const processedData = generateProductCardsData2(data, vendorName);
                            section.items = processedData;
                        }
                    } catch (sectionError) {
                        console.error(`Error processing section ${section.name}:`, sectionError);
                    }
                    if (section.include) {
                        try {
                            const includeUrl = new URL(section.include, `${CMS_HOST_URL}${env == 'qa' ? 'stage' : 'prod'}/`);
                            const includeDataPromise = await fetch(includeUrl.toString(), {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            });
                            const includeData = await includeDataPromise.json();
                            Object.assign(section, includeData);
                        } catch (includeError) {
                            console.error(`Error including data for section ${section.name}:`, includeError);
                        }
                    }
                });

                await Promise.all(sectionPromises);
                console.log('All sections processed');
                return cmsData;
            } catch (error) {
                console.error('Error processing sections:', error);
                return null;
            }
        };

        await processSections().then((result) => {
            if (result) {
                console.log('Processing completed successfully');
            } else {
                console.log('Processing failed');
            }
        }).catch((error) => {
            console.error('Unexpected error:', error);
        });
        return cmsData;

    } catch (e) {
        console.error("Error in fetching cms data", e);
    }
}

const getBrandPathEntry = (vendorName: string) => {
    return vendorName && vendorName !== 'nestingale' ? `/${vendorName}` : "";
}

export const renderFunctions: RenderFunctionType = {
    Accordion,
    Carousel,
    CategoriesCarousel,
    CirclerImageButton,
    ContentOnImage,
    CustButton,
    CustImage,
    CustText,
    ImageButton,
    ItemsGrid,
    MarkdownTextPanel,
    Panel,
    ProductsCarousel,
    Slider
};

export const renderSection = (sectionKey: string, sectionData: any, props: any, isSection?: boolean) => {
    console.log("renderSection...sectionKey: ", sectionKey);
    sectionData = sectionData || props.message.cmsData.sections[sectionKey];
    if (!sectionData) {
        console.error(`No section found for key: ${sectionKey}`);
        return null;
    }
    let renderFunction: string = sectionData.uiCompType?.method?.replace(/^render/, '') || "Panel";
    const sectionStyles = sectionData.uiCompType?.sectionStyles || {};

    return (
        isSection ? (
            <section key={sectionKey} style={sectionStyles} className={cssStyles.section}>
                {renderFunction && renderFunction in renderFunctions
                    ? renderFunctions[renderFunction](sectionData, props)
                    : Panel(sectionData, props)}
            </section>
        ) : (
            <>
                {renderFunction && renderFunction in renderFunctions
                    ? renderFunctions[renderFunction](sectionData, props)
                    : Panel(sectionData, props)}
            </>
        )
    );
};

const CmsRenderer = (props: any) => {
    const { vendorName, cmsData } = JSON.parse(props.message);
    const { sectionCmsData, sectionCmsKey } = props;

    return (
        <>
            {(sectionCmsData && sectionCmsKey)
                ? <React.Fragment key={sectionCmsKey}>
                    {renderSection(sectionCmsKey, sectionCmsData, props, true)}
                </React.Fragment>
                : cmsData.order?.map((secKey: string, index: number) => (
                    <React.Fragment key={secKey}>
                        {secKey && cmsData.sections[secKey] && renderSection(secKey, cmsData.sections[secKey], props, (index == 0 ? false : true))}
                    </React.Fragment>
                ))}
        </>
    )
}

export default CmsRenderer;
