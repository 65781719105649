// hooks/dataHooks.js
import axiosObj from '@/store/axios';
import { deleteGoogleCredentials, getGoogleCredentialsFromLS } from '@/load_and_get_google_access_token';
import { deleteFBKCredentialsFromLS } from './load_and_get_facebook_access_token';
import { CART_TOKEN, GCART_TOKEN, getLoggedInEmailAddress } from '.';
import { Auth } from 'aws-amplify';
import { STP_ROOM_TYPE_SEL_LS_KEY } from '@/components/style_profile';
import { deleteDirectCognitoCredentialsFromLS } from '@/components/login';



function baseURL() {
    if (typeof window !== 'undefined') {
        return `${location.protocol}//${location.host}`;
    }
    return "";
}

function apibaseURL() {
    if (typeof window !== 'undefined') {
        if(location.host.indexOf("localhost")>-1){
            return "https://nestingale.com";
        }
        return baseURL();
    }
    return "";
}
const TEMP_CUSTOMER_ID_LS_KEY = "tcId";
const CUSTOMER_ID_LS_KEY = "cId";
const CUSTOMER_STYLEPROF_ID_LS_KEY = "cSpfId";
let BASE_URL = baseURL();
let BRAND = "";
export const API_BASE_URL = "https://nestingale.com";


export function getStpRoomTypeFromLS() {
    if(typeof window !== "undefined"){
        let roomType = '';
        try{
            //get the style profile room type selection
            roomType = localStorage.getItem(STP_ROOM_TYPE_SEL_LS_KEY) || 'null'; //get the style profile room type selection
          }catch(e){
              console.log("--------useUserInfo.ts---------FAILED TO GET THE SESSION STORAGE ID");
          }
    
          return roomType;
    }
    return null;
}

export function getCustIdFromLS() {
    try{
        return localStorage.getItem(CUSTOMER_ID_LS_KEY) || '';
    }catch(e){}
    return '';
}

export function removeCustIdFromLS () {
    try{
        localStorage.removeItem(CUSTOMER_ID_LS_KEY);
    }catch(e){}
}


export function removeTempCustIdFromLS () {
    try{
        localStorage.removeItem(TEMP_CUSTOMER_ID_LS_KEY);
    }catch(e){}
}

export function setTempCustIdToLS (value="") {
    try{
        localStorage.setItem(TEMP_CUSTOMER_ID_LS_KEY,value);
    }catch(e){}
}

export function getTempCustIdFromLS () {
    try{
        return localStorage.getItem(TEMP_CUSTOMER_ID_LS_KEY);
    }catch(e){}
    return ""
}

export function getEitherCustIdFromLs () {
    return getCustIdFromLS() || getTempCustIdFromLS();
}

export function setCurrentBrand(currentBrand=""){
    if(currentBrand!="nestingale"){
        BRAND = currentBrand;
    }
}

export function getCurrentBrand(){
    return BRAND;
}

export const getStpRoomTypeSel = () => {
    try{
      localStorage.getItem(STP_ROOM_TYPE_SEL_LS_KEY);
    }catch(e){
        console.log("--------useUserInfo.ts---------FAILED TO GET TO STP_ROOM_TYPE_SEL_LS_KEY FROM LS");
    }
}

export const setStpRoomTypeSel = (roomTypeSel : string) => {
    try{
      localStorage.setItem(STP_ROOM_TYPE_SEL_LS_KEY, roomTypeSel);
    }catch(e){
        console.log("--------useUserInfo.ts---------FAILED TO SET TO STP_ROOM_TYPE_SEL_LS_KEY FROM LS");
    }
}

export const removeStpRoomTypeSel = () => {
    try{
      localStorage.removeItem(STP_ROOM_TYPE_SEL_LS_KEY);
    }catch(e){
        console.log("--------useUserInfo.ts---------FAILED TO DELETE TO STP_ROOM_TYPE_SEL_LS_KEY FROM LS");
    }
}

export function getCustStyleProfileIdFromLS() {
    try{
        return localStorage.getItem(CUSTOMER_STYLEPROF_ID_LS_KEY) || '';
    }catch(e){}
    return '';
}

export function setCustStyleProfileIdToLS(custId:string, styleProfileId: string) {
    try{
        return localStorage.setItem(CUSTOMER_STYLEPROF_ID_LS_KEY, custId+":"+styleProfileId);
    }catch(e){}
}

export function removeCustStyleProfileIdFromLS () {
    try{
        localStorage.removeItem(CUSTOMER_STYLEPROF_ID_LS_KEY);
    }catch(e){}
}

export async function signOutUser(toReset:boolean = false) {
    //clear local storage
    deleteGoogleCredentials();
    deleteFBKCredentialsFromLS();
    deleteDirectCognitoCredentialsFromLS();

    if (typeof window !== 'undefined') {
        try{
            removeCustStyleProfileIdFromLS();
            removeCustIdFromLS();
        }catch(e){
            console.log("--------useUserInfo.ts---------FAILED TO DELETE TO localStorage THE ID");
        }
        
        removeStpRoomTypeSel();
        //@ts-ignore
        window.setCookieWithoutSecure(CART_TOKEN,null,10);
         //@ts-ignore
        window.setCookieWithoutSecure(GCART_TOKEN,null,10);
    } 
    //now clear cart
    try {
        const url = `${BASE_URL}/api/signoutuser`;
        const response = await axiosObj.get(url);
        if(response.data){
            try {
                await Auth.signOut();
                console.log('User signed out');
            } catch (error) {
                console.error('Error signing out:', error);
            }
            //window.location.reload();
            if(toReset){
                const currentUrl = window.location.href;
                window.location.href = "/login?next="+currentUrl;
            } else {
                window.location.href = "/rooms"
            }
        }
        return response.data;
    } catch (error) {
        console.error('Error fetching user details:', error);
        try {
            await Auth.signOut();
            console.log('User signed out');
        } catch (error) {
            console.error('Error signing out:', error);
        }
        throw error;
    }
}

async function fetchData(url:string) {
    try {
        const response = await axiosObj.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching user details:', error);
        throw error;
    }
    return null;
}

let CART_INFO_COUNTER = 0;
let USER_INFO_COUNTER = 0;

// Fetch user info
export async function getUserInfo(email: string="",
        sendTempCustomerId:boolean=false,
        recheckStyleProfileId:boolean=false,
        selectedStyleProfileScheme:string=""): Promise<any> {
    const emailAddress = email || getLoggedInEmailAddress();
    console.log("--------useUserInfo.ts---------emailAddress=",emailAddress, "sendTempCustomerId:", sendTempCustomerId);
    let savecustomerprofileAPIUrl = `${API_BASE_URL}/v1/api/customer/savecustomerprofile?includeStyleProfile=true`;
    const headers = {
        'content-type': "application/json"
    };
    let customerId = null;
    let styleProfileId = null;

    if(emailAddress && !selectedStyleProfileScheme){
        try{
            customerId = getCustIdFromLS();
            styleProfileId = getCustStyleProfileIdFromLS();
            if (styleProfileId && styleProfileId.startsWith(customerId)) {
                styleProfileId = styleProfileId.replace(customerId+":", '');
                recheckStyleProfileId = false;
            } else {
                //if styleProfileId exists in LS but it's not this customer's for some reason, then delete it.
                styleProfileId && removeCustStyleProfileIdFromLS();
            }
        }catch(e){
            console.log("--------useUserInfo.ts---------FAILED TO GET THE SESSION STORAGE ID");
        }
        //if customerId is not present in localStorage then make API call to get it
        //if localStorage accessing is an issue in that browser make an api call - rare case
        if(!customerId || recheckStyleProfileId){
            const savecustomerprofilePayload = {
                email: emailAddress
            } as any;

            if(sendTempCustomerId){
                const tempCustID = getTempCustIdFromLS();
                try{
                    if(tempCustID){
                        savecustomerprofilePayload.customerID = tempCustID;
                    }
                }catch(e){}
            }
            
            try{
                console.log(`--useUserInfo.ts calling savecustomerprofile API with payload: `, savecustomerprofilePayload);

                let customerIdResponse = await axiosObj.post(savecustomerprofileAPIUrl, savecustomerprofilePayload, headers as any);
                customerId =  customerIdResponse?.data?.customerID;
                styleProfileId = customerIdResponse?.data?.styleProfileID;
                console.log(`--useUserInfo.ts savecustomerprofile API response`, customerIdResponse, customerId, styleProfileId);
                if(customerId){
                    try{
                        localStorage.setItem(CUSTOMER_ID_LS_KEY,customerId);
                        styleProfileId && setCustStyleProfileIdToLS(customerId, styleProfileId);
                        removeTempCustIdFromLS();
                    }catch(e){
                        console.log("--------useUserInfo.ts---------FAILED TO SAVE TO localStorage STORAGE THE ID");
                    }
                }
            }catch(e){
                const errorObj = Object.assign({description: "NEST_SPA:Slices:cID:Exception", errorCode: "NEST_SPA:customerIdResponse"},e)
                console.log("--------useUserInfo.ts ERRORZZZ ---------", e);
                console.error(e);
                //if creating the user Id is failed then treat as guest
                if(USER_INFO_COUNTER <= 2){
                    ++USER_INFO_COUNTER;
                    return getUserInfo();
                }else{
                    USER_INFO_COUNTER=0;
                    return null;
                }
            }
        }
        console.log("--------useUserInfo.ts---------customerId=",customerId);
        return {
            cId: customerId,
            email: emailAddress,
            styleProfileID: styleProfileId
        }
    } else { // Setting guest/temporary customer id when not logged in 
        try{
            try{
                customerId = localStorage.getItem(TEMP_CUSTOMER_ID_LS_KEY);
            }catch(e){
                console.log("--------useUserInfo.ts---------FAILED TO READ THE TEMP SESSION STORAGE ID FROM LOCAL STORAGE");
            }
            if(!customerId || selectedStyleProfileScheme) {
                let customerIdResponse = await axiosObj.post(savecustomerprofileAPIUrl, {
                    email : emailAddress, 
                    selectedStyleProfileScheme: selectedStyleProfileScheme, 
                    customerID: customerId || getCustIdFromLS() }, headers as any);
                customerId = customerIdResponse?.data?.customerID;
                if(customerId){
                    try{
                        localStorage.setItem(TEMP_CUSTOMER_ID_LS_KEY,customerId);
                    }catch(e){
                        console.log("--------useUserInfo.ts---------FAILED TO SAVE TEMP_CUSTOMER_ID_LS_KEY TO localStorage");
                    }
                }
            }
            return {
                cId: customerId,
                email: null,
                unauthorized: true 
            }
        }catch(e){
            const errorObj = Object.assign({description: "NEST_SPA:Slices:cID:Exception", errorCode: "NEST_SPA:customerIdResponse"},e)
            console.log("--------useUserInfo.ts---------", e);
            console.error(e);
            //if creating the user Id is failed then treat as guest
            if(USER_INFO_COUNTER <= 2){
                ++USER_INFO_COUNTER;
                return getUserInfo();
            }else{
                USER_INFO_COUNTER=0;
                return null;
            }
        }
    }
    return null; // for guest/temp user always returning null here
}

export interface CartInfo {
    cId: string;
    cartCount?: number;
    cartId?: string;
    email?: string;
    userId?: string;
    error?: boolean;
    cartItems?:any;
}

export const getUserCartInfo = (userInfo: any) => {
    if (userInfo.cId) {
        const cartData = localStorage.getItem('CartInfo');
        if (cartData) {
            const cartDataObj = JSON.parse(cartData);
            if (cartDataObj?.userId !== userInfo.cId) {
                localStorage.removeItem('cartData');
                return fetchAndUpdateCartDetails();
            }
            if (cartDataObj?.cId === userInfo.cId && cartDataObj?.crtD) {   //TODO : check  
                return cartDataObj;
            }
        } else {
            return fetchAndUpdateCartDetails();
        }
    }

    function fetchAndUpdateCartDetails() {
        return fetchCartDetails(userInfo.cId).then((cartInfo: CartInfo) => {
            if (cartInfo.error) {
                return cartInfo;
            }
            localStorage.setItem('cartData', JSON.stringify(cartInfo));
            userInfo.crtD = cartInfo;
            return cartInfo;
        });
    }
}

export async function fetchCartDetails(customerId: string): Promise<CartInfo> {
    if(customerId){
        const getCartAPIUrl = `${API_BASE_URL}/v1/api/cartId?userId=${customerId}`;   
        try{
            const headers = {
                'content-type': "application/json"
            };
            let  custCartResponse = await axiosObj.get(getCartAPIUrl, headers as any);
            console.log("--------useUserInfo.ts---------custCartData=",custCartResponse.data);
            let data = JSON.parse(JSON.stringify(custCartResponse.data));
            if ((data && Object.keys(data).length === 0) || data?.cartId) {
                return  {cId : customerId, ...data};
            } else {
                return {cId: customerId, error:true};
            }
            
        }catch(err){
            const errorObj = Object.assign({description: "NEST_SPA:Slices:crtD:Exception", errorCode: "NEST_SPA:custCartResponse"},err)
            console.error(errorObj);
            
            if(CART_INFO_COUNTER <= 2){
                ++CART_INFO_COUNTER;
                return fetchCartDetails(customerId);
            }else{
                CART_INFO_COUNTER=0;
                return {cId: customerId, error:true};
            }
        }         
    }
    return {cId: customerId, error:true};
}


// Fetch cart data
export async function updateCartData(userInfo: any, checkoutState: any, cartItem: any=null, setCartItem: any=null, overwrite:boolean= false, deletedItem:boolean=false, upatedIfDeletedCartItem:any=null,createNewCart:boolean=false) {

        if(!checkoutState || Object.keys(checkoutState).length <=0) {
            console.log("--------useUserInfo.ts---------no checkout object");
            return;
        }
        const savedDBUserCartDetails = userInfo.crtD;
        const currentCartCount = checkoutState.lineItems.reduce((initialValue:number, eachLineItem:any) => {
            return eachLineItem.quantity+ initialValue;
        },0)
        var productInfo = checkoutState.lineItems;
        // if(window.location.href.indexOf("/product") >= 0){
        //     const sku = window.location.href.split("/product")[1].split("?")[0].replace("/","")
        //     productInfo = checkoutState.lineItems.filter((eachItem:any)=>{
        //         return eachItem.variant.sku == sku
        //     })  
        // }else if(window.location.href.indexOf("cart") >= 0 && cartItem){
        //    productInfo = JSON.parse(JSON.stringify(cartItem))
        //    console.log("deleted productInfo",productInfo)
        //    upatedIfDeletedCartItem(false);
        //    setCartItem(null)
        // }


        const cartItems = productInfo?.map((eachItem: any) => {
            return  {
                    amount: eachItem.variant ? eachItem.variant.priceV2 ? eachItem.variant.priceV2.amount : "no V2 pricing " :"no variant Info",
                    productHandle: eachItem.variant ? eachItem.variant.product ? eachItem.variant.product.handle : "no product info" :"no variant Info",
                    variantId: eachItem.variant ? eachItem.variant.id :"no variant Id",
                    sku: eachItem.variant ? eachItem.variant.sku :"no variant Info",
                    quantity: eachItem.quantity,
                    title: eachItem.title
                }  
            }
        )

       

        /**
         * DB userId and Currently LoggedIn User, User ID must match &&
         * if the db does not have shopify cart.js cartId or if db cart Id token is not maching with Shopify Cart token
         */
        //user ID comparision with DB saved UserId
        if (savedDBUserCartDetails && 
            (!savedDBUserCartDetails.userId || (savedDBUserCartDetails.userId == userInfo.cId)) &&
            (!savedDBUserCartDetails.cartId) || (checkoutState.id == savedDBUserCartDetails.cartId || createNewCart)) {
            const requestData = {
                "userId": userInfo.cId,
                "cartId":  checkoutState.id,
                "cartCount": currentCartCount,
                overwrite,
                cartItems: cartItems
            }
            const headers =  {
                'content-type': "application/json"
            }
            if(!deletedItem){
                try {
                    const response = await axiosObj.post(`${API_BASE_URL}/v1/api/cartId`, requestData, headers as any);
                    console.log("--------useUserInfo.ts---------Succesful updating cart data:", response.data);
                    return response.data;
                } catch (e) {
                    console.log("--------useUserInfo.ts---------Failed to update cart data:", e);
                    return null;
                }
            }else{
                try {
                    const response = await axiosObj.delete(`${API_BASE_URL}/v1/api/cartId`, {
                        data: requestData,
                        headers: headers
                    });
                    console.log("--------useUserInfo.ts---------Succesful deleting cart data:", response.data);
                    return response.data;
                } catch (e) {
                    console.log("--------useUserInfo.ts---------Failed to deleting cart data:", e);
                    return null;
                }
            }
            
        }
}

export const fetchFavorites = async (customerId:string) => {
    let favorites = JSON.parse(localStorage.getItem('favorites') || '[]');
    if (customerId) {
        try {
            const response = await axiosObj.get(
                `${API_BASE_URL}/v1/api/customer-favorites?customerId=${customerId}`
            );
            const backendFavorites = response.data.map(
                (item: { itemId: string }) => item.itemId
            );
            favorites = [...favorites, ...backendFavorites];
        } catch (error) {
            console.error('Error fetching favorite status:', error);
        }
    }
    return favorites;
};

export const postFavorite = async (customerId:string, favoriteData:any) => {
    let response = {}
    try {
        response = await axiosObj({
            method: 'post',
            url: `${API_BASE_URL}/v1/api/customer-favorites`,
            data: favoriteData,
            headers: { 'Content-Type': 'application/json' },
        });
    } catch (error) {
        console.error('Error fetching favorite status:', error);
        return error;
    }
    return response;
};

export const deleteFavorite = async (customerId:string, favoriteData:any) => {
    let response = {}
    try {
        response = await axiosObj({
            method: 'delete',
            url: `${API_BASE_URL}/v1/api/customer-favorites`,
            data: favoriteData,
            headers: { 'Content-Type': 'application/json' },
        });
    } catch (error) {
        console.error('Error fetching favorite status:', error);
        return error;
    }
    return response;
};