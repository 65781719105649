import React, { useState, useEffect, useRef, useCallback, ReactNode } from "react";
import { Button, CircularProgress,Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import cssStyles from "./roomplanonboarding.module.scss";
import Box from '@mui/material/Box';
import { gtmPageOnloadTracking, gtmClickTracking } from "@/utils";
import { Texture } from '@/store/builderSlice';
import WallTextureTray from '@/components/builder/WallTextureTray';
import FloorTextureTray from '@/components/builder/FloorTextureTray';
import { useDispatch } from 'react-redux';
import { actions as builderActions } from "@/store/builderSlice";
import { enablePatches } from "immer";
import { useRouter } from "next/router";
import { getUserInfo } from "@/utils/useUserInfo";
import { Placeholder } from "@aws-amplify/ui-react";
import Modal from '@mui/material/Modal';


// Define the interface for room label items
interface RoomLabelItem {
    customerId: string;
    roomType: string;
    roomLabel: string;
    roomId: string;
}

interface CreateRoomPlanProps {
    setActiveComponent: (component: string) => void;
    designImage?: any;
    onCloseOverlay?: Function;
    currentRoomType?: string;
    isModelRequired?: boolean;
    roomId?:string;
}

const CreateRoomPlan: React.FC<CreateRoomPlanProps> = ({setActiveComponent, designImage, onCloseOverlay, currentRoomType, isModelRequired=false,roomId=''}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [name, setName] = useState('');
  const [roomType, setRoomType] = useState<string>('');
  const [lengthFeet, setLengthFeet] = useState<number>(12);
  const [lengthInches, setLengthInches] = useState<number>(0);
  const [widthFeet, setWidthFeet] = useState<number>(15);
  const [widthInches, setWidthInches] = useState<number>(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [userInfo, setUserInfo] = useState<any | null>(null);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false); 
  const [isLoaderVisible, setLoaderVisibility] = useState<boolean>(false);
  const [surfaceType, setSurfaceType] = useState<string | null>(null);
  const tempSelectedWallType = useRef<any | null>({ id: "C2BCb5", hexCode: '#C2BCb5', surfaceType: 'Wall', type: 'color', name: 'C2BCb5' });
  const tempSelectedFloorType = useRef<any | null>({ id: "t5", textureUrl: "/floors/wood/Oak.png", surfaceType: 'Floor', type: 'image', name: 'Oak', category: 'Wood' });
  const [selectedWallType, setSelectedWallType] = useState<any | null>(tempSelectedWallType.current);
  const [selectedFloorType, setSelectedFloorType] = useState<any | null>(tempSelectedFloorType.current);
//   const [selectedWallType, setSelectedWallType] = useState<any | null>();
//   const [selectedFloorType, setSelectedFloorType] = useState<any | null>();
  const [imageSrc, setImageSrc] = useState("");
  const [placeholder, setPlaceholder] = useState('');
  const [counter, setCounter] = useState(1); // Initial counter
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    gtmPageOnloadTracking({
      event: 'selects Create',
      pageType: "selects Create"
    });
  }
, []);

  useEffect(() => {
    // Set the current date as MM/DD/YY
    let roomTypePlacHolderValue = currentRoomType? currentRoomType.toLowerCase(): designImage? designImage.roomType : roomType;
    const today = new Date();
    const formattedDate = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear().toString().slice(-2)}`;
    setPlaceholder(`${roomTypePlacHolderValue} ${counter} - ${formattedDate}`);
  }, [roomType, counter]);

  useEffect(() => {
    // Function to call the API
    const fetchRoomLabels = async () => {
      try {
        const response = await fetch('https://api.nestingale.com/v1/api/customer-onboarding/room-labels', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            CustomerID: userInfo.cId,
            // CustomerID: "5f70e35b-32a6-4f09-bb79-bceb3dde30bc",
            roomType: roomType.toLowerCase()
          })
        });

        const data = await response.json();

        // Check for a match
        const matchFound = data.some((item: RoomLabelItem) => item.roomLabel === name || item.roomLabel === placeholder);

        if (matchFound) {
          setError('The room label already exists. Please choose a different label.');
        } else {
          setError('');
        }
      } catch (error) {
        console.error('Error fetching room labels:', error);
      }
    };

    // Only fetch if there's a user-entered value or placeholder to validate
    if (name || placeholder) {
        fetchRoomLabels();
      }
    }, [name, placeholder, roomType]);

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
        setCounter((prevCounter) => prevCounter + 1); // Increment counter for each name change
      };

  useEffect(() => {
    if (roomType) {
        const lowercasedRoomType = roomType.toLowerCase() as keyof typeof roomImages;
        if (lowercasedRoomType in roomImages) {
            setImageSrc(roomImages[lowercasedRoomType]);
        }
    }
}, [roomType]);

  useEffect(() => {
    let textures = getTextures();
    if(textures.textures.length > 0) {
        dispatch(builderActions.updateDesignContent(textures));
    }
    gtmPageOnloadTracking({
        event: 'page_viewed',
        pageType: "Basics Info Screen",
    });

    const loadUserInfo = async() => {
        const userInfo = await getUserInfo("",true);
        setUserInfo(userInfo);
    }

    loadUserInfo();

    handleResize();
    enablePatches();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect( ()=> {
    if(router.query && router.query.roomType) {
        setRoomType(router.query.roomType as string);
    }
  }, [router.query.roomType]);

  const getTextures = () => {
    let textures = {
        textures: [
            { id: "t1", textureUrl: "/walls/bricks.jpeg", surfaceType: 'Wall', type: 'image', name: 'Bricks' },
            { id: "t2", textureUrl: "/walls/wall-orange.jpg", surfaceType: 'Wall', type: 'image', name: 'Wall-Orange' },
            { id: "t3", textureUrl: "/walls/flowers.jpg", surfaceType: 'Wall', type: 'image', name: 'Flowers' },
            { id: "t4", textureUrl: "/walls/botanical.jpg", surfaceType: 'Wall', type: 'image', name: 'Botanical' },
            { id: "defaultWallTexture", textureUrl: "/walls/defaultWallTexture.jpg", surfaceType: 'Wall', type: 'image', name: 'defaultWallTexture' },
            { id: "C2BCb5", hexCode: '#C2BCb5', surfaceType: 'Wall', type: 'color', name: 'C2BCb5' },
            { id: "F4F2EE", hexCode: '#F4F2EE', surfaceType: 'Wall', type: 'color', name: 'F4F2EE' },
            { id: "DCD7D0", hexCode: '#DCD7D0', surfaceType: 'Wall', type: 'color', name: 'DCD7D0' },
            { id: "B4A696", hexCode: '#B4A696', surfaceType: 'Wall', type: 'color', name: 'B4A696' },
            { id: "t5", textureUrl: "/floors/wood/Oak.png", surfaceType: 'Floor', type: 'image', name: 'Oak', category: 'Wood' },
            { id: "t6", textureUrl: "/floors/wood/Maple.png", surfaceType: 'Floor', type: 'image', name: 'Maple', category: 'Wood' },
            { id: "t7", textureUrl: "/floors/wood/Cherry.png", surfaceType: 'Floor', type: 'image', name: 'Cherry', category: 'Wood' },
            { id: "t8", textureUrl: "/floors/wood/Walnut.png", surfaceType: 'Floor', type: 'image', name: 'Walnut', category: 'Wood' },
            { id: "t9", textureUrl: "/floors/wood/Ash.png", surfaceType: 'Floor', type: 'image', name: 'Ash', category: 'Wood' },
            { id: "t10", textureUrl: "/floors/wood/Mahogany.png", surfaceType: 'Floor', type: 'image', name: 'Mahogany', category: 'Wood' },
    
            { id: "t11", textureUrl: "/floors/carpet/Nylon.png", surfaceType: 'Floor', type: 'image', name: 'Nylon', category: 'Carpet' },
            { id: "t12", textureUrl: "/floors/carpet/Polyester.png", surfaceType: 'Floor', type: 'image', name: 'Polyester', category: 'Carpet' },
            { id: "t13", textureUrl: "/floors/carpet/Acrylic.png", surfaceType: 'Floor', type: 'image', name: 'Acrylic', category: 'Carpet' },
            { id: "t14", textureUrl: "/floors/carpet/Wool.png", surfaceType: 'Floor', type: 'image', name: 'Wool', category: 'Carpet' },
            { id: "t15", textureUrl: "/floors/carpet/Pile.png", surfaceType: 'Floor', type: 'image', name: 'Pile', category: 'Carpet' },
    
            { id: "t16", textureUrl: "/floors/tile/Ceramic.png", surfaceType: 'Floor', type: 'image', name: 'Ceramic', category: 'Tile' },
            { id: "t17", textureUrl: "/floors/tile/Stone.png", surfaceType: 'Floor', type: 'image', name: 'Stone', category: 'Tile' },
            { id: "t18", textureUrl: "/floors/tile/Mosaic.png", surfaceType: 'Floor', type: 'image', name: 'Mosaic', category: 'Tile' },
            { id: "t19", textureUrl: "/floors/tile/Glass.png", surfaceType: 'Floor', type: 'image', name: 'Glass', category: 'Tile' },
            { id: "t20", textureUrl: "/floors/tile/Terracotta.png", surfaceType: 'Floor', type: 'image', name: 'Terracotta', category: 'Tile' },
    
            { id: "t21", textureUrl: "/floors/concrete/Polished.png", surfaceType: 'Floor', type: 'image', name: 'Polished', category: 'Concrete' },
            { id: "t22", textureUrl: "/floors/concrete/Stained.png", surfaceType: 'Floor', type: 'image', name: 'Stained', category: 'Concrete' },
            { id: "t23", textureUrl: "/floors/concrete/Stamped.png", surfaceType: 'Floor', type: 'image', name: 'Stamped', category: 'Concrete' },
            { id: "t24", textureUrl: "/floors/concrete/Epoxy.png", surfaceType: 'Floor', type: 'image', name: 'Epoxy', category: 'Concrete' },
            { id: "t25", textureUrl: "/floors/concrete/Micro-Topping.png", surfaceType: 'Floor', type: 'image', name: 'Micro-Topping', category: 'Concrete' },
    
            // { id: "t6", textureUrl: "./floors/floor-texture2.jpg", surfaceType: 'Floor', type: 'image', name: 'floor-texture2', category: 'Carpet' },
            // { id: "t7", textureUrl: "./floors/floor-texture3.jpg", surfaceType: 'Floor', type: 'image', name: 'floor-texture3', category: 'Tile' },
            // { id: "t8", textureUrl: "./floors/floor-texture4.jpg", surfaceType: 'Floor', type: 'image', name: 'floor-texture4', category: 'Concrete' },
            // { id: "t9", textureUrl: "./floors/floor-texture5.jpg", surfaceType: 'Floor', type: 'image', name: 'floor-texture5', category: 'Wood' }
        ]
    }

    return textures;
  };


  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767); // Detect mobile screen
    setIsTablet(window.innerWidth > 767 && window.innerWidth <= 1024); // Tablet from 768px to 1024px
  };

  const onFloorTextureUpdate = useCallback((texture: Texture) => {
    setSelectedFloorType(texture);
    setIsOverlayOpen(false)
  }, []);

  const onWallColorUpdate = useCallback((texture: Texture) => {
    console.log(texture);
    tempSelectedWallType.current = texture;
    setSelectedWallType(texture);
    setIsOverlayOpen(false);
  }, []);

  interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        style={{
          position: 'absolute',
          backgroundColor: '#ffffff',
          borderRadius: '15px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          padding: '2%',
          zIndex: '10'
        }}
        className={`builder-tabpanel ${cssStyles.roomPlanTabPanel}`}
      >
        {value === index && children}
        {/* <div className={`arrow-left pos-${index}`}><img src={arrowLeft.src} alt="arrow" /></div> */}
      </div>
    );
  }
const surfaceTypeHandler = (surfaceType: string | null = null,  selectedType: string | null = null) =>{
    setSurfaceType(surfaceType);

    if (selectedType === 'wall') {
        setSelectedWallType(tempSelectedWallType.current); // Update wall type
    } else if (selectedType === 'floor') {
        setSelectedFloorType(tempSelectedFloorType.current); // Update floor type
    }

    setIsOverlayOpen(!isOverlayOpen);
}

const roomImages = {
    bedroom: "assets/img/Choose_Bedroom.png",
    diningroom: "assets/img/Choose_DinningRoom.png",
    livingroom: "assets/img/Choose_LivingRoom.png",
    office: "assets/img/Choose_Office.png",
};

const nextButtonClicked = () => {
    console.log('designnnnnnnnnn',designImage)
    if (!error) {
    gtmClickTracking({
        event: 'Next_clicked',
        pageType: "Basics Info Screen",
    });
    let length = `${lengthFeet}'${lengthInches}`;
    let width = `${widthFeet}'${widthInches}`;
    let wallColor = selectedWallType.hexCode.substring(1);
    let floorType = selectedFloorType.category;
    let floorTypeValue = selectedFloorType.name;
    let encodedImageUrl = encodeURIComponent(designImage?.designImages);
    let encodedStyleName = encodeURIComponent(designImage?.style);
    if (currentRoomType == undefined) {
        currentRoomType = roomType;
    }
    let url = (designImage != undefined) ? `/builder?roomType=${currentRoomType}&createRoom=true&auth=false&roomLabel=${name?name:placeholder}&l=${length}&w=${width}&wc=${wallColor}&ft=${floorType}&ftv=${floorTypeValue}&designId=${designImage?.designId}&designImage=${encodedImageUrl}&style=${encodedStyleName}${roomId ? `&roomId=${roomId}` : ''}&saveProject=true`
                                         : `/builder?roomType=${currentRoomType}&createRoom=true&auth=false&roomLabel=${name?name:placeholder}&l=${length}&w=${width}&wc=${wallColor}&ft=${floorType}&ftv=${floorTypeValue}${roomId ? `&roomId=${roomId}` : ''}&saveProject=true`;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.get('auth') == 'false') {
        let urlcid = urlParams.get('cid');
        if (urlcid == undefined || urlcid == "") {
            url += `&cid=${userInfo.cId}`;
        } else {
            url += `&cid=${urlcid}`;
        }
    }

    if (router.query.vendor !== 'nestingale') {
        url = `/${router.query.vendor}` + url;        
    }

    window.location.href = url;
    } else {
        console.log('error');
    }
}

const getCreateRoomPlanContent = () => {
    if(isMobile || isTablet) {
        return (
            <div className={isModelRequired ? cssStyles.container : cssStyles.containerCreateRoomPlan}>
                <Box component="div" className={cssStyles.mainContainer}>
                    {
                        isModelRequired && <div className={cssStyles.backAndCloseIconMWeb} >
                            <ArrowBackIosIcon sx={{ fontSize: 30, color:'#a5a4a9' }} onClick={() => setActiveComponent('')} />
                            <CloseIcon sx={{ fontSize: 20, color:'#595959'}} onClick={() => { setIsModalOpen(false); onCloseOverlay?.();} }/>
                        </div>
                    }
                {/* Circle */}
                <Box component="div" className={cssStyles.CircleContainer} style={{height: (isMobile && !isTablet) ? '24%' : undefined}}>
                    <Box sx={{ 
                        width: { xs: '100px', sm: '80px', md: '100px', lg: '100px' }, 
                        height: { xs: '100px', sm: '80px', md: '100px', lg: '100px' } 
                        }} 
                        component="img"
                        src={designImage? designImage.designImages : imageSrc?imageSrc:"assets/img/1.0.jpg"}
                        alt='img'
                        className={cssStyles.CircularImg} />
                    </Box>


                {/* Header Section */}
                    <Box component="div"  className={cssStyles.header} >
                        <Typography component="h2" variant="h2" className={cssStyles.headerText1} style={{fontSize:'27px'}}>Tell us a bit more </Typography>
                        <Typography component="h2" variant="h2" className={cssStyles.headerText2}>about this {currentRoomType? currentRoomType.toLowerCase() : designImage? designImage.roomType  : roomType.toLowerCase()}:</Typography>
                    </Box>
                
                    

                    {/* Form */}
                    <Box component='div' className={cssStyles.form}>
                        <div className={cssStyles.formGroup}>
                            <label htmlFor="name">Name:</label>
                            <input type="text" id="name"  placeholder={placeholder}  value={name} onChange={handleNameChange}/>
                        </div>
                        {error && <p style={{ color: 'red', marginBottom:'10px', marginTop: '5px', textAlign: 'center' }}>{error}</p>}



                        <div className={cssStyles.formGroupDimensions}>
                            <label htmlFor="length">Length:</label>
                            <div className={cssStyles.inputPair}>
                                <input type="number" id="length-feet" placeholder="Feet" value={lengthFeet} onChange={(e) => setLengthFeet(parseInt(e.target.value))} /><span className={cssStyles.ftAndIn}> ft </span>
                                <input type="number" id="length-inches" placeholder="Inches" value={lengthInches} onChange={(e) => setLengthInches(parseInt(e.target.value))} /><span className={cssStyles.ftAndIn}> in </span>
                            </div>
                        </div>

                        <div className={cssStyles.formGroupDimensions}>
                            <label htmlFor="width">Width:</label>
                            <div className={cssStyles.inputPair}>
                                <input type="number" id="width-feet" placeholder="Feet" value={widthFeet} onChange={(e) => setWidthFeet(parseInt(e.target.value))} /> <span className={cssStyles.ftAndIn}> ft </span>
                                <input type="number" id="width-inches" placeholder="Inches" value={widthInches} onChange={(e) => setWidthInches(parseInt(e.target.value))} /> <span className={cssStyles.ftAndIn}> in </span>
                            </div>
                        </div>
                        {isOverlayOpen && isMobile &&
                            <TabPanel value={1} index={1}>
                            {/* {tabTitleArr[tabName] && <span className="tab_title_mweb" style={{ fontWeight: '700', fontSize: '16px' }}>{tabTitleArr[tabName]}</span>} */}
                                <div style={{ display: 'flex', justifyContent:'space-between'}}><span></span><p className={cssStyles.overlayText}>SELECT YOUR FLOOR TYPE</p><CloseIcon sx={{ fontSize: 15 }} onClick={() => surfaceTypeHandler()}/></div>
                                {surfaceType == 'floor' && <FloorTextureTray applyTexture={onFloorTextureUpdate} />}
                                {surfaceType == 'wall' && <WallTextureTray isTextureNotRequired={true} onColorUpdate={onWallColorUpdate} />}
                            </TabPanel>
                        }

                        <div className={cssStyles.formGroupColorFloor}>
                            <div className={cssStyles.colorContainer}>
                                <label htmlFor="wall-color">Wall Color:</label>
                                <div 
                                    className={cssStyles.floorPicker} 
                                    style={{
                                        backgroundColor: selectedWallType?.hexCode ? selectedWallType?.hexCode : 'none',
                                    }} 
                                    onClick={() => surfaceTypeHandler('wall', 'wall')}
                                >    
                                </div>
                            </div>
                            <div className={cssStyles.floorContainer}>
                                <label htmlFor="floor-type">Floor Type:</label>
                                {/* <div className={cssStyles.floorPicker} style={{backgroundImage: `url(${selectedFloorType.textureUrl})` }} onClick={() => surfaceTypeHandler('floor')}></div> */}
                                <div 
                                    className={cssStyles.floorPicker} 
                                    style={{
                                        backgroundImage: selectedFloorType?.textureUrl ? `url(${selectedFloorType.textureUrl})` : 'none',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat'
                                    }} 
                                    onClick={() => surfaceTypeHandler('floor', 'floor')}
                                >    
                                </div>
                            </div>
                        </div>
                        
                    </Box>
                    {/* Button */}
                        <Button variant="contained" 
                        className={cssStyles.mainButton}
                        onClick={nextButtonClicked}
                        disabled={!!error} 
                        sx={{
                            '&:disabled': {
                                backgroundColor: '#fea27d !important', // Force background color with !important
                                color: 'white !important',    
                            },
                        }}
                        >
                            Next
                        </Button>
                    {/* <Typography className={cssStyles.noteText}>Room scanning is only available on Apple Pro devices. Have a room with an usual shape? More customization will be available in the future!</Typography> */}
                </Box>
                </div>
        );
    }

    return (
        <div className={cssStyles.container}>
            <Box component="div" className={cssStyles.mainContainer}>
                {
                    isModelRequired &&
                    <div className={cssStyles.BackIcon}>
                        <ArrowBackIosIcon sx={{ fontSize: 30, color:'#a5a4a9' }} onClick={() => setActiveComponent('')}/>
                    </div>
                }
                {/* Circle */}
                <Box component="div"  className={cssStyles.CircleContainer}>
                <Box sx={{
                    width: { xs: '100px', sm: '80px', md: '100px', lg: '100px' }, 
                    height: { xs: '100px', sm: '80px', md: '100px', lg: '100px' } 
                    }} 
                    component="img" 
                    src={designImage? designImage.designImages : imageSrc?imageSrc:"assets/img/1.0.jpg"}
                    alt='img' 
                    className={cssStyles.CircularImg} />
                </Box>
            {/* Header Section */}
                <Box component='div' className={cssStyles.formContainer}>
                {
                    isModelRequired &&
                    <div className={cssStyles.CloseIcon}>
                        <CloseIcon sx={{ fontSize: 20, color:'#595959'}} onClick={() => {setIsModalOpen(false); onCloseOverlay?.();}}/>
                    </div>
                }
                <Box component="div"  className={cssStyles.header} >
                    <Typography component="h2" variant="h2" className={cssStyles.headerText1}>Tell us a bit more about this {currentRoomType? currentRoomType.toLowerCase() : designImage? designImage.roomType : roomType.toLowerCase()}:</Typography>
                    {/* <Typography component="h2" variant="h2" className={cssStyles.headerText2}></Typography> */}
                </Box>
            
                {/* Form */}
                <Box component='div' className={cssStyles.form}>
                    <div className={cssStyles.formGroup}  style={{ marginBottom: error ? '0px' : '22px' }}>
                        <label htmlFor="name">Name:</label>
                        <input type="text" id="name"  placeholder={placeholder}  value={name} onChange={handleNameChange} />
                    </div>
                    {error && <p style={{ color: 'red', marginBottom:'10px', marginTop: '5px', textAlign:'center' }}>{error}</p>}


                    {isOverlayOpen && !isMobile &&
                    <TabPanel value={1} index={1}>
                    {/* {tabTitleArr[tabName] && <span className="tab_title_mweb" style={{ fontWeight: '700', fontSize: '16px' }}>{tabTitleArr[tabName]}</span>} */}
                        <div style={{ display: 'flex', justifyContent:'space-between'}}><span></span><p className={cssStyles.overlayText}>SELECT YOUR {surfaceType?.toUpperCase()} TYPE</p><CloseIcon sx={{ fontSize: 15 }} onClick={() => surfaceTypeHandler()}/></div>
                        {surfaceType == 'floor' && <FloorTextureTray applyTexture={onFloorTextureUpdate} />}
                        {surfaceType == 'wall' &&  <WallTextureTray isTextureNotRequired={true} onColorUpdate={onWallColorUpdate} />}
                    </TabPanel>
                    }

                    <div className={cssStyles.formGroupDimensions}>
                        <label htmlFor="length">Length:</label>
                        <div className={cssStyles.inputPair}>
                            <input type="number" id="length-feet" placeholder="Feet" value={lengthFeet} onChange={(e) => setLengthFeet(parseInt(e.target.value))} /> <span className={cssStyles.ftAndIn}> ft </span>
                            <input type="number" id="length-inches" placeholder="Inches" value={lengthInches} onChange={(e) => setLengthInches(parseInt(e.target.value))} /> <span className={cssStyles.ftAndIn}> in </span>
                        </div>
                    </div>

                    <div className={cssStyles.formGroupDimensions}>
                        <label htmlFor="width">Width:</label>
                        <div className={cssStyles.inputPair}>
                            <input type="number" id="width-feet" placeholder="Feet" value={widthFeet} onChange={(e) => setWidthFeet(parseInt(e.target.value))} /> <span className={cssStyles.ftAndIn}> ft </span>
                            <input type="number" id="width-inches" placeholder="Inches" value={widthInches} onChange={(e) => setWidthInches(parseInt(e.target.value))} /> <span className={cssStyles.ftAndIn}> in </span>
                        </div>
                    </div>

                    <div className={cssStyles.formGroupColorFloor}>
                        <div className={cssStyles.colorContainer}>
                            <label htmlFor="wall-color">Wall Color:</label>
                            <div 
                                className={cssStyles.floorPicker} 
                                style={{
                                    backgroundColor: selectedWallType?.hexCode ? selectedWallType?.hexCode : 'none',
                                }} 
                                onClick={() => surfaceTypeHandler('wall', 'wall')}
                            >    
                            </div>
                        </div>
                        <div className={cssStyles.floorContainer}>
                            <label htmlFor="floor-type">Floor Type:</label>
                            <div 
                                className={cssStyles.floorPicker} 
                                style={{
                                    backgroundImage: selectedFloorType?.textureUrl ? `url(${selectedFloorType.textureUrl})` : 'none',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                }} 
                                onClick={() => surfaceTypeHandler('floor', 'floor')}
                            >    
                            </div>
                        </div>
                    </div>
                    
                </Box>
                {/* Button */}
                <Button variant="contained" className={cssStyles.mainButton}
                    onClick={nextButtonClicked}
                >
                    Next
                </Button>

            </Box>

            </Box>
        </div>
    );
};

return (
    <>
        <main className="home-container">
            {
                isModelRequired
                ?   
                    (isModalOpen && <Modal 
                        open={isModalOpen} 
                        onClose={() => setIsModalOpen(false)}
                        disablePortal
                        disableEnforceFocus
                        disableAutoFocus
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {getCreateRoomPlanContent()}
                    </Modal>)
                : 
                    getCreateRoomPlanContent()
            }

                {isLoaderVisible && (
                        <CircularProgress/>
                    )
                }
                
        </main>
     
      
    </>
  );
};

export default CreateRoomPlan;