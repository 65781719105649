

import {createSlice, createAsyncThunk, current, bindActionCreators} from '@reduxjs/toolkit';
import axiosObj from './axios';
import { createAction } from '@reduxjs/toolkit';
import { ProfileData } from '../components/onboarding/profile';
import { ProjectGoalInput } from '../components/onboarding/project_goal';
import { Section } from '../components/onboarding/questions';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import axios from "axios";

const initialState = {
  loading: "loading" as any,
  currentRequestId: "" as any,
  customerID: "" as any,
  roomId: null as string | null,
  profileData: {} as ProfileData,
  projectID: "" as any,
  sectionsInfoData: [] as any,
  currentTab :{index: 0, currentSection: {}} as any,
  pickCurrentSectionDetailsToState: false,
  navigateToProjectGoal:false,
  moveToLoginPage: false,
  currentQuestionnaireInfo : {
    questionSequence: 0,
    previousQuestionSequence: 0,
    selectedAnswerValueChanged: false,//used for comparision of answers
    sectionID: "",
    nextSectionID:"",
    firstQuestionNumber:"",//used only once when changed to nextSection first question
    noOfQuestionsCount: 0,
    questionID: ""
  } as any,
  isMinSelQuestion: false as any,
  customerRoomLabelResponse: [] as any,
  allQuestionsData: {} as any,
  sectionQuestion: null as any,
  styleProfileData: {} as any,
  updatedAvatarImage : false,
  updateStyleprofileResponse: {} as any,
  questionnarireSummaryInfo: {} as any,
  updatedHowDidWedo : false,
  customerFlowType: "",
  rooms3Ddata: {} as any,
  error: "" as any,
  roomTypesData: [] as any,
  styleSchemesData: [] as any
}

export const welcomePageData = (state: any) => state.welcomePg;

export const resetStyleProfileState = createAction('welcomePage/resetStyleProfileState')
export const resetState = createAction('welcomePage/resetState')
export const setCurrentQuestion = createAction('welcomePage/setCurrentQuestionDetails')



interface SaveCustomerStyleProfileInput {
  CustomerID: string;
  EmailAddress: string;
  questionnaireFlowType: string;
  brand?: string;
}


interface getStyleProfileInput {
  styleProfileID: string;
  EmailAddress: string | null;
  brand?: string;
}

interface GetQuestionnaireResponseInput {
  customerID: string;
  brand?: string;
}

interface rooms3dAPIInput {
  roomType?: string;
  customerID: string;
  brand?: string;
  roomLabel?: string;
  roomId?: string;
  mode?:string;
}




export const getRooms3DInfo = createAsyncThunk(
  "welcomePage/getRooms3DData",
  async (postData : rooms3dAPIInput, { rejectWithValue }) => {
    try {
      const headers =  {
          'content-type': "application/json"
      }
      let url = `/api/rooms3Ddata`;
      
      if(postData.customerID){
        url += `?customerId=${postData.customerID}`;
      } else {
        return rejectWithValue({description: "NEST_SPA:Slices:getRooms3DInfo:Missing Customer ID", errorCode: "NEST_SPA:HOME_PAGE"});}
      
      if(postData.roomType && postData.roomType!="null"){
        url += `&roomType=${postData.roomType}` 
      }
      if(postData.roomId){
        url += `&roomId=${postData.roomId}`
      }
      if(postData.roomLabel){
        url += `&roomLabel=${postData.roomLabel}`
      }
      if(postData.brand && postData.brand !== 'nestingale'){
        url += "&vendor="+postData.brand 
      }
      if(postData.mode) {url += `&mode=${postData.mode}`}

      const getRooms3DData = await axiosObj.get(url)
          .then((response) => {
             return response.data
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:getRooms3DInfo:Exception", errorCode: "NEST_SPA:HOME_PAGE"},err)
            console.log(errorObj);
            return rejectWithValue(err);
            //return welcomeProfile;
          });

          console.log("getRooms3DData=",getRooms3DData)
      return getRooms3DData;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:getRooms3DInfo:Exception", errorCode: "NEST_SPA:HOME_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
  }
);

export const getQuestionnaireSummmary = createAsyncThunk(
  "welcomePage/getQuestionnaireResponseData",
  async (postData : GetQuestionnaireResponseInput, { rejectWithValue }) => {
    try {
      const headers =  {
          'content-type': "application/json"
      }
      let url = `/api/customeronboarding/GetCustomerQuestionnaireResponse`;
      url += `?customerID=${postData.customerID}` 
      if(postData.brand && postData.brand !== 'nestingale'){
        url += "&vendor="+postData.brand 
      }
      const getQuestionnaireResponseData = await axiosObj.get(url)
          .then((response) => {
             return response.data
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:getQuestionnaireResponse:Exception", errorCode: "NEST_SPA:HOME_PAGE"},err)
            console.log(errorObj);
            return rejectWithValue(err);
            //return welcomeProfile;
          });

          console.log("getQuestionnaireResponseData=",getQuestionnaireResponseData)
      return getQuestionnaireResponseData;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:getQuestionnaireResponse:Exception", errorCode: "NEST_SPA:HOME_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
  }
);

const styleSchemeSelection = [{"colorScheme":"Classic_Rustic_Charm","colorSchemeDisplayName":"Classic Rustic Charm","colorSchemeImage":"https://d221a61rb87vel.cloudfront.net/Images/styleprofile/collages/Classic_Rustic_Charm.jpg"},{"colorScheme":"Old_Hollywood_Glam","colorSchemeDisplayName":"Old Hollywood Glam","colorSchemeImage":"https://d221a61rb87vel.cloudfront.net/Images/styleprofile/collages/Old_Hollywood_Glam.jpg"},{"colorScheme":"Minimalist_Industrial_Chic","colorSchemeDisplayName":"Minimalist Industrial Chic","colorSchemeImage":"https://d221a61rb87vel.cloudfront.net/Images/styleprofile/collages/Minimalist_Industrial_Chic.jpg"},{"colorScheme":"Eclectic_Bohemian","colorSchemeDisplayName":"Eclectic Bohemian","colorSchemeImage":"https://d221a61rb87vel.cloudfront.net/Images/styleprofile/collages/Eclectic_Bohemian.jpg"},{"colorScheme":"Coastal_Casual","colorSchemeDisplayName":"Coastal Casual","colorSchemeImage":"https://d221a61rb87vel.cloudfront.net/Images/styleprofile/collages/Coastal_Casual.jpg"},{"colorScheme":"Rustic_Mediterranean","colorSchemeDisplayName":"Rustic Mediterranean","colorSchemeImage":"https://d221a61rb87vel.cloudfront.net/Images/styleprofile/collages/Rustic_Mediterranean.jpg"},{"colorScheme":"Scandinavian","colorSchemeDisplayName":"Scandinavian","colorSchemeImage":"https://d221a61rb87vel.cloudfront.net/Images/styleprofile/collages/Scandinavian.jpg"}]

export const getStyleSchemes = createAsyncThunk(
  "welcomePage/getStyleSchemeSelectionData",
  async (postData:any, { rejectWithValue }) => {
    try {
      const headers =  {
          'content-type': "application/json"
      }
      let url = `https://api.nestingale.com/v1/api/customer-onboarding/style-selection-choices`;
      if(postData && postData.brand && postData.brand !== 'nestingale'){
        url += "&vendor="+postData.brand 
      }
      const getStyleSchemeSelectionResponse = await axiosObj.get(url)
          .then((response) => {
             return response.data
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:getQuestionnaireResponse:Exception", errorCode: "NEST_SPA:HOME_PAGE"},err)
            console.log(errorObj);
            //return styleSchemeSelection;
            return rejectWithValue(err);
            //return welcomeProfile;
          });

          console.log("getStyleSchemeSelectionData=",getStyleSchemeSelectionResponse)
      return getStyleSchemeSelectionResponse;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:getStyleSchemes:Exception", errorCode: "NEST_SPA:HOME_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
  }
);

export const getStyleProfile = createAsyncThunk(
  "welcomePage/getStyleProfilesData",
  async (postData : getStyleProfileInput, { rejectWithValue }) => {
    try {
      const headers =  {
          'content-type': "application/json"
      }
      let url = `/api/customeronboarding/getcustomerstyleprofile/${postData.styleProfileID}/${postData.EmailAddress}`;
      if(postData.brand && postData.brand !== 'nestingale'){
        url += "?vendor="+postData.brand 
      }
      const getStyleProfileData = await axiosObj.get(url)
          .then((response) => {
             return response.data
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:getStyleProfileData:Exception", errorCode: "NEST_SPA:HOME_PAGE"},err)
            console.log(errorObj);
            return rejectWithValue(err);
            //return welcomeProfile;
          });

          console.log("getStyleProfileData=",getStyleProfileData)
      return getStyleProfileData;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:getStyleProfile:Exception", errorCode: "NEST_SPA:HOME_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
  }
);

interface UpdateStyleProfileInput {
  brand?: string;
  styleProfileID: string;
  myAvatarImage?:string;
  howDidWedo?: string;
}


export const updateStyleprofile = createAsyncThunk(
  "welcomePage/updateStyleprofileData",
  async (postData : UpdateStyleProfileInput, { rejectWithValue }) => {
    try {
      const headers =  {
          'content-type': "application/json"
      }
      let url = "/api/styleprofile";
      if(postData.brand && postData.brand !== 'nestingale'){
        url += "?vendor="+postData.brand 
      }
      const updateStyleprofileData = await axiosObj.post(url, postData, headers as any)
          .then((response) => {
             return response.data
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:updateStyleprofileData:Exception", errorCode: "NEST_SPA:HOME_PAGE"},err)
            console.log(errorObj);
            return rejectWithValue(err);
            //return welcomeProfile;
          });

          console.log("saveCustomerStyleProfileData=",updateStyleprofileData)
      return updateStyleprofileData;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:fetchCarousalRoomsList:Exception", errorCode: "NEST_SPA:HOME_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
  }
);


export const saveCustomerStyleProfile = createAsyncThunk(
  "welcomePage/saveCustomerStyleProfileData",
  async (postData : SaveCustomerStyleProfileInput, { rejectWithValue }) => {
    try {
      const headers =  {
          'content-type': "application/json"
      }
      let url = "https://api.nestingale.com/v1/api/customer-onboarding/style-profile";
      const type = (postData.questionnaireFlowType.toLocaleLowerCase() == "customer") ? "Customer" : (postData.questionnaireFlowType.toLocaleLowerCase() == "designer") ? "InteriorDesigner": "DefaultCustomer"
      postData.questionnaireFlowType = type;

      if(postData.brand && postData.brand !== 'nestingale'){
        url += "?vendor="+postData.brand 
      }
      const saveCustomerStyleProfileData = await axios.post(url, postData, headers as any)
          .then((response) => {
             return response.data
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:saveCustomerStyleProfile:Exception", errorCode: "NEST_SPA:HOME_PAGE"},err)
            console.log(errorObj);
            return rejectWithValue(err);
            //return welcomeProfile;
          });

          console.log("saveCustomerStyleProfileData=",saveCustomerStyleProfileData)
      return saveCustomerStyleProfileData;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:fetchCarousalRoomsList:Exception", errorCode: "NEST_SPA:HOME_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
  }
);

export const postProjectGoal = createAsyncThunk(
  "welcomePage/postProjectGoalData",
  async (postData : ProjectGoalInput, { rejectWithValue }) => {
    try {
      const headers =  {
          'content-type': "application/json"
      }
      let url = "/api/customeronboarding/savecustomerproject";
      if(postData.brand && postData.brand !== 'nestingale'){
        url += "?vendor="+postData.brand 
      }
      const postCustSpecificProfileData = await axiosObj.post(url, postData, headers as any)
          .then((response) => {
             return response.data
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:postProfileData:Exception", errorCode: "NEST_SPA:HOME_PAGE"},err)
            console.log(errorObj);
            return rejectWithValue(err);
            //return welcomeProfile;
          });

          console.log("postCustSpecificProfileData=",postCustSpecificProfileData)
      return postCustSpecificProfileData;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:fetchCarousalRoomsList:Exception", errorCode: "NEST_SPA:HOME_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
  }
);

interface getCustomerRoomLabelProps {
  CustomerID:string;
  roomType:string;
  brand?: string;
}

export const getCustomerRoomLabel = createAsyncThunk(
  "welcomePage/getCustomerRoomLabelData",
  async (postData : getCustomerRoomLabelProps, { rejectWithValue }) => {
    try {
      const headers =  {
          'content-type': "application/json"
      }
      let url = "https://api.nestingale.com/v1/api/customer-onboarding/room-labels";
      if(postData.brand && postData.brand !== 'nestingale'){
        url += "?vendor="+postData.brand 
      }
      const getCustomerRoomLabelData = await axios.post(url, postData, headers as any)
          .then((response) => {
             return response.data
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:getCustomerRoomLabel:Exception", errorCode: "NEST_SPA:HOME_PAGE"},err)
            console.log(errorObj);
            return rejectWithValue(err);
            //return welcomeProfile;
          });

          console.log("getCustomerRoomLabelData=",getCustomerRoomLabelData)
      return getCustomerRoomLabelData;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:getCustomerRoomLabel:Exception", errorCode: "NEST_SPA:HOME_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
  }
);

interface SaveCustomerForNextQuestionData {
  previousQuestionSequence: number;
  SectionID: string;
  NextQuestionSectionID?: string;
  NextQuestionSequence: number;
  CustomerID: string;
  questionID?: string;
  responses?: any;
  title: string;
  brand?: string;
  shortQuestion?: string;
  customerType: string;
  roomType?: string;
  roomLabel?: string;
  roomId?: string;
  isRoomQuizComplete?: boolean;
  IsApplyStyleToRoom?: boolean;
  IsStyleQuizComplete?: boolean;
  env?:string;
}



export const saveCustResponseReturnnExtQuestionData =  createAsyncThunk(
  "welcomePage/savecustresponsereturnnxtquestionData",
  async (postData : SaveCustomerForNextQuestionData, { rejectWithValue }) => {
    try {
      const headers =  {
          'content-type': "application/json"
      }

      let url = "https://api.nestingale.com/v1/api/customer-onboarding/global-preferences";
      const type = postData.customerType.toLocaleLowerCase();

      if(type == "customer" || type == "designer" || type == "roomq"){
          url = "https://api.nestingale.com/v1/api/customer-onboarding/next-question";
          //url = "http://localhost:3002/v1/api/customer-onboarding/next-question";
//          url = "https://nestingale.com/v1/api/customeronboarding/savecustresponsereturnnextquestion";
      }

      if(postData.brand && postData.brand !== 'nestingale'){
        url += "?vendor="+postData.brand;
      }
      
      let questionResponse = await axios.post(url, postData, headers as any)
          .then((response) => {
             return response.data
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:saveCustResponseReturnnExtQuestionData:Exception", errorCode: "NEST_SPA:HOME_PAGE"},err)
            console.log(errorObj);
            //return welcomeProfile;
            return rejectWithValue(err);
          });

          console.log("postCustSpecificProfileData=",questionResponse)
      return questionResponse;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:saveCustResponseReturnnExtQuestionData:Exception", errorCode: "NEST_SPA:HOME_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
})

export const postProfileData = createAsyncThunk(
  "welcomePage/postProfileData",
  async (postData : ProfileData, { rejectWithValue }) => {
    try {
      const headers =  {
          'content-type': "application/json"
      }
      let url = "/api/customer/savecustomerprofile";
      if(postData.brand && postData.brand !== 'nestingale'){
        url += "?vendor="+postData.brand 
      }
      if(postData.includeStyleProfile) {
        url += (url.indexOf("?") > -1) ? "&includeStyleProfile=true" : "?includeStyleProfile=true";
      }
      //url = 'http://localhost:3002'+url;
      const postCustSpecificProfileData = await axiosObj.post(url, postData, headers as any)
          .then((response) => {
             return response.data
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:postProfileData:Exception", errorCode: "NEST_SPA:HOME_PAGE"},err)
            console.log(errorObj);
            return rejectWithValue(err);
            //return welcomeProfile;
          });

          console.log("postCustSpecificProfileData=",postCustSpecificProfileData)
      return postCustSpecificProfileData;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:postProfileData:Exception", errorCode: "NEST_SPA:HOME_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
  }
);
interface sectionDataParams {
  customerType: string;
  isHideRoomTypeQuestion: boolean,
  brand?: string;
  styleProfileID?: string;
  IsApplyStyleToRoom?: boolean;
}
export const getSectionsData = createAsyncThunk(
  "welcomePage/getSectionData",
  async (config: sectionDataParams, { rejectWithValue }) => {
    try {
      const headers =  {
          'content-type': "application/json"
      }
      
      const type = (config.customerType.toLocaleLowerCase() == "customer") ? "Customer" : (config.customerType.toLocaleLowerCase() == "designer") ? "InteriorDesigner": (config.customerType.toLocaleLowerCase() == "roomq") ?"RoomQuiz" : "DefaultCustomer"
      let url = `https://api.nestingale.com/v1/api/customer-onboarding/section-counts`;
      if(config.brand && config.brand !== 'nestingale'){
        url += "?vendor="+config.brand 
      }
      const env = process.env.NEXT_PUBLIC_APP_ENV === 'qa' ? 'qa' : 'prod'; 
      // const env='qa'
      const postData = {
        "sectionType": type,
        "isHideRoomTypeQuestion": config.isHideRoomTypeQuestion || false,
        //"styleProfileID": config.styleProfileID || undefined,
        "IsApplyStyleToRoom": config.IsApplyStyleToRoom || false,
        env,
      }
      
      const getSectionsInfoData = await axios.post(url, postData, headers as any)
          .then((response) => {
             return response.data
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:getSectionsData:Exception", errorCode: "NEST_SPA:WELCOME_PAGE"},err)
            console.log(errorObj);
            //return welcomeProfile;
            return rejectWithValue(err);
          });

          console.log("getSectionsInfoData=",getSectionsInfoData)
      return getSectionsInfoData;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:fetchCarousalRoomsList:Exception", errorCode: "NEST_SPA:HOME_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
  }
);
interface SetCurrentQuestionPayload {
  state: any;
  action: QuestionPayload
}
interface QuestionPayload {
  type: string;
  payload : {
    previous: boolean;
    next: boolean;
  }
}

const handleQuestionState = (state :any, action: any) => {

  let currentSection = state.sectionsInfoData[state.currentTab.index] as Section;
  state.navigateToProjectGoal = false;
  
  if(action.payload.previous){
    if(state.currentQuestionnaireInfo.previousQuestionSequence <= 0){
      if(!(state.sectionsInfoData[state.currentTab.index - 1])){
        //considering 1st question here
        state.navigateToProjectGoal = false;
        return;
      }
      state.currentTab.index =  state.currentTab.index - 1;
      currentSection = state.sectionsInfoData[state.currentTab.index] as Section;
      state.currentTab.currentSection =  currentSection;
      state.currentQuestionnaireInfo.questionSequence = currentSection.count;
      state.currentQuestionnaireInfo.previousQuestionSequence = state.currentQuestionnaireInfo.questionSequence-1;
      state.currentQuestionnaireInfo.nextSectionID = currentSection.sectionID;
      state.currentQuestionnaireInfo.navigateToNextSectionUsingCache = true;
    } else {
      state.currentQuestionnaireInfo.questionSequence--;
      //previous sequence is perfect for array-meaning if we are seeing first question :: questionSequence=1 and previousQuestionSequence=0

      //previousQuestionSequence is used to get the stored question,i.e, in 0th index 1st question will be present, but questionSequence=1
      state.currentQuestionnaireInfo.previousQuestionSequence = state.currentQuestionnaireInfo.questionSequence-1;
    }
    if(state.allQuestionsData[currentSection.title] && state.allQuestionsData[currentSection.title][state.currentQuestionnaireInfo.previousQuestionSequence]) {
      const questionData = state.allQuestionsData[currentSection.title][state.currentQuestionnaireInfo.previousQuestionSequence]
      state.sectionQuestion = questionData;

      state.currentQuestionnaireInfo["shortQuestion"] = questionData.NextQuestionAnswerChoices.shortQuestion;
      state.currentQuestionnaireInfo["questionID"] = questionData.NextQuestionAnswerChoices.questionID;
    }
    state.sectionQuestion.isLastQuestion = state.currentQuestionnaireInfo.questionSequence === currentSection.count;
  }else{

    const nextSectionData = state.sectionsInfoData[state.currentTab.index + 1];

    if(action.payload.pickNextSectionFirstQuestion){

      
      if(!nextSectionData){
        return;
      }

      state.currentQuestionnaireInfo.nextSectionID = nextSectionData.sectionID;
      state.currentQuestionnaireInfo.firstQuestionNumber = nextSectionData.sequences[0];
      
      //will trigger a call
      state.sectionQuestion = null;
      console.log(current(nextSectionData));
      return;
    }else if(state.currentQuestionnaireInfo.questionSequence >= currentSection.count || state.pickCurrentSectionDetailsToState){
      
      
      if(!nextSectionData){
        return;
      }
      //if all questions are finished, reset the counter and getNext Section info data(if present)
      state.currentQuestionnaireInfo.questionSequence = 1;
      state.currentQuestionnaireInfo.previousQuestionSequence = state.currentQuestionnaireInfo.questionSequence - 1;
      state.currentTab.index =  state.currentTab.index + 1;
      currentSection = nextSectionData as Section;
      state.currentTab.currentSection =  currentSection;
      state.currentQuestionnaireInfo.nextSectionID = nextSectionData.sectionID;
      state.pickCurrentSectionDetailsToState = false;
      state.currentQuestionnaireInfo.navigateToNextSectionUsingCache = false;
    }else{
      //storing previous value and then incrementing
      state.currentQuestionnaireInfo.previousQuestionSequence = state.currentQuestionnaireInfo.questionSequence;
      ++state.currentQuestionnaireInfo.questionSequence;
    }
    //console.log(current(state.currentQuestionnaireInfo.questionSequence))
    if(state.allQuestionsData[currentSection.title] && 
      state.allQuestionsData[currentSection.title][state.currentQuestionnaireInfo.previousQuestionSequence]){
        console.log("previousQuestionSequence=",state.currentQuestionnaireInfo.previousQuestionSequence)
        const questionData = state.allQuestionsData[currentSection.title][state.currentQuestionnaireInfo.previousQuestionSequence]
        console.log(current(questionData))
        state.currentQuestionnaireInfo["shortQuestion"] = questionData.NextQuestionAnswerChoices.shortQuestion;
        state.currentQuestionnaireInfo["questionID"] = questionData.NextQuestionAnswerChoices.questionID;
        state.sectionQuestion = questionData;
    }
    state.sectionQuestion.isLastQuestion = state.currentQuestionnaireInfo.questionSequence === currentSection.count;
  }
  const template =  state.sectionQuestion.NextQuestionAnswerChoices.questions[0].template
  state.isMinSelQuestion = parseInt(template.min_selections) > 0
}

const updateQuestionColorChoicesInState = function(state:any,statePayload:any,singleQuestion:any , answers:any) {
  if(singleQuestion?.template?.type == "color_pallete_single_selection"){
    const colorObj = {} as any;
    statePayload.questionColorChoices = null;
    singleQuestion.choices.map((eachColorChoice:any)=>{
      const choice = eachColorChoice.choice.split("-");
        if(!colorObj[choice[0]]){
          colorObj[choice[0]] = [];
        }

        colorObj[choice[0]].push({color:choice[1],selected:false, choice: eachColorChoice.choice, choiceid: eachColorChoice.choiceid });

        answers.map((eachAnswer:any)=>{
          if(eachAnswer.answer){
            const choice = eachAnswer.answer.split("-");
            
            if(colorObj[choice[0]]){
                colorObj[choice[0]].map((eachColorObj:any)=>{
                if(eachColorObj.choiceid == eachAnswer.choiceid){
                  eachColorObj.selected = true;
                }
              })
            }
          }
        })

    })
    statePayload.questionColorChoices = colorObj;
  };
}

interface ResetStyleProfile {
  updatedAvatarImage?: boolean;
}

interface StyleProfilePayload {
  payload: ResetStyleProfile | undefined;
}

export const getRoomTypesData = createAsyncThunk(
  "welcomePage/getRoomTypesData",
  async (config: {brand?: string, status?: string}, { rejectWithValue }) => {
    try {
      const headers =  {
          'content-type': "application/json"
      }
      
      let url = `/api/roomTypes`;
      if(config.brand && config.brand !== 'nestingale'){
        url += "?vendor="+config.brand 
      }
      if(config.status && config.status == 'active') {
        url += (url.includes("?") ? '&' : '?') + 'status=active';
      }

      const getRoomTypesData = await axiosObj.get(url, headers as any)
          .then((response) => {
             return response.data?.roomTypes
          },(err)=>{
            const errorObj = Object.assign({description: "NEST_SPA:Slices:getRoomTypesData:Exception", errorCode: "NEST_SPA:WELCOME_PAGE"},err)
            console.log(errorObj);
            return rejectWithValue(err);
          });

          console.log("getRoomTypesData=",getRoomTypesData)
      return getRoomTypesData;
    } catch (err) {
      console.log({description: "NEST_SPA:Slices:fetchCarousalRoomsList:Exception", errorCode: "NEST_SPA:HOME_PAGE", err_desc: (err as Error).message, err_stack: (err as Error).stack});
      return rejectWithValue(err);
    }
  }
);


const WelcomePageSlice = createSlice({
    name: "WelcomePage",
    initialState: initialState,
    reducers: {
      resetRooms3DInfo : (state) => {  
        state.rooms3Ddata = {} as any;// Resetting the variable to its initial value 
      },
      resetMoveToLoginPage: (state) => {
        state.moveToLoginPage = false;// Resetting the variable to its initial value
      }
    },
    extraReducers: (builder) => {
      
        builder.addCase(getStyleSchemes.fulfilled, (state, { meta, payload, type }) => {
            state.styleSchemesData = payload;
            console.log(type);
        })
        builder.addCase(getRoomTypesData.fulfilled, (state, { meta, payload, type }) => {
            state.roomTypesData = payload;
            console.log(type);
        })
        builder.addCase(getRoomTypesData.rejected, (state, { meta, payload, error }) => {
              state.roomTypesData = payload;
              state.error = error as string;
        })
        builder.addCase(resetState, (state, action) => {
              state.isMinSelQuestion=false;
              return;
            //if (meta.requestId === state.currentRequestId.requestId) {
              state.loading = "loading";
              state.currentRequestId = "";
              state.moveToLoginPage = false;
              //state.projectID = "";
              state.sectionsInfoData= [];
              state.currentTab ={index: 0, currentSection: {}};
              state.pickCurrentSectionDetailsToState = false;
              state.navigateToProjectGoal =false;
              state.currentQuestionnaireInfo = {
                  questionSequence: 0,
                  previousQuestionSequence: 0,
                  selectedAnswerValueChanged: false,//used for comparision of answers
                  sectionID: "",
                  nextSectionID:"",
                  firstQuestionNumber:"",//used only once when changed to nextSection first question
                  noOfQuestionsCount: 0,
                  questionID: ""
              }
              state.allQuestionsData= {};
              state.sectionQuestion= null;
              
              state.error= "";
        })

        builder.addCase(resetStyleProfileState, (state,  { payload }:any) => {
          if(payload?.updatedAvatarImage) {
            state.updatedAvatarImage = false;
          }
          if(payload?.updatedHowDidWedo) {
            state.updatedHowDidWedo = false;
          }
        })

        builder.addCase(setCurrentQuestion, handleQuestionState)

        
        builder.addCase(getCustomerRoomLabel.fulfilled, (state, { meta, payload, type }) => {
          //if (meta.requestId === state.currentRequestId.requestId) {
            state.customerRoomLabelResponse = payload;
            state.loading = "success";
            state.currentRequestId = "";
            console.log(type);
          
        })
        builder.addCase(getCustomerRoomLabel.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta;
                state.loading = "failed";
                state.customerRoomLabelResponse = payload;
                state.error = error as string;
            }
        })

        builder.addCase(postProfileData.fulfilled, (state, { meta, payload, type }) => {
            //if (meta.requestId === state.currentRequestId.requestId) {
              //const tempPayload = {customerID: payload, styleProfileID: "XXXXXXXXXX"} as ProfileData;

              state.customerID = payload.customerID;
              state.profileData = payload as ProfileData;
              state.loading = "success";
              state.currentRequestId = "";
              console.log(type);
            
        })
        builder.addCase(postProfileData.rejected, (state, { meta, payload, error }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta;
                state.loading = "failed";
                state.error = error as string;
            }
        })

        builder.addCase(postProjectGoal.fulfilled, (state, { meta, payload, type }) => {
          //if (meta.requestId === state.currentRequestId.requestId) {
            state.projectID = payload;
            state.loading = "success";
            state.currentRequestId = "";
            console.log(type);
          
        })
        builder.addCase(postProjectGoal.rejected, (state, { meta, payload, error }) => {
          
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta;
                state.loading = "failed";
                state.projectID = payload;
                state.error = error as string;
            }
        })

        builder.addCase(getRooms3DInfo.fulfilled,(state, { meta, payload, type }) => {
          //if (meta.requestId === state.currentRequestId.requestId) {
            state.rooms3Ddata = payload;
            state.loading = "success";
            state.currentRequestId = "";
            console.log(type);
          
        })

        builder.addCase(getRooms3DInfo.rejected, (state, { meta, payload, error }) => {
          
          //if (meta.requestId === state.currentRequestId.requestId) {
              state.currentRequestId = meta;
              state.loading = "failed";
              state.rooms3Ddata = payload;
              state.error = error as string;
              //debugger
          //}
      })

        builder.addCase(getQuestionnaireSummmary.fulfilled, (state, { meta, payload, type }) => {
          //if (meta.requestId === state.currentRequestId.requestId) {
            state.questionnarireSummaryInfo = payload;
            state.loading = "success";
            state.currentRequestId = "";
            console.log(type);
          
        })
        builder.addCase(getQuestionnaireSummmary.rejected, (state, { meta, payload, error }) => {
          
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = meta;
                state.loading = "failed";
                state.questionnarireSummaryInfo = payload;
                state.error = error as string;
            }
        })
          

        builder.addCase(updateStyleprofile.fulfilled, (state, { meta, payload, type }) => {
          state.updateStyleprofileResponse = payload;
          if(meta.arg.myAvatarImage){
            state.updatedAvatarImage = true;
            state.updatedHowDidWedo = false;
          }else if(meta.arg.howDidWedo){
            state.updatedHowDidWedo = true;
            state.updatedAvatarImage = false;
          }
        })
        builder.addCase(updateStyleprofile.rejected, (state, { meta, payload, error }) => {
          state.updateStyleprofileResponse = payload;
          state.updatedHowDidWedo = false;
          state.updatedAvatarImage = false;
          state.error = error as string;
      })


        builder.addCase(saveCustomerStyleProfile.fulfilled, (state, { meta, payload, type }) => {
          //if (meta.requestId === state.currentRequestId.requestId) {
            payload.IsEditMode = true;
            state.styleProfileData = payload;
            console.log(type);
        })
        builder.addCase(saveCustomerStyleProfile.rejected, (state, { meta, payload, error }) => {
              state.styleProfileData = payload;
              state.error = error as string;
        })

        builder.addCase(getStyleProfile.fulfilled, (state, { meta, payload, type }) => {
          //if (meta.requestId === state.currentRequestId.requestId) {
            state.styleProfileData = payload;
            console.log(type);
        })
        builder.addCase(getStyleProfile.rejected, (state, { meta, payload, error }) => {
              state.styleProfileData = payload;
              state.error = error as string;
        })

        builder.addCase(getSectionsData.fulfilled, (state, { meta, payload, type }) => {
          //if (meta.requestId === state.currentRequestId.requestId) {
            const customerType = (meta.arg.customerType.toLocaleLowerCase() == "customer") ? "Customer" : (meta.arg.customerType.toLocaleLowerCase() == "designer") ? "InteriorDesigner": (meta.arg.customerType.toLocaleLowerCase() == "roomq") ? "RoomQuiz" : "DefaultCustomer"
            state.sectionsInfoData = payload || [];
            state.customerFlowType = customerType;
            state.loading = "success";
            state.currentRequestId = "";
            console.log(type);
        })
        builder.addCase(getSectionsData.rejected, (state, { meta, payload, error }) => {
          if (meta.requestId === state.currentRequestId.requestId) {
              state.currentRequestId = meta;
              state.loading = "failed";
              state.sectionsInfoData = payload || [];
              state.error = error as string;
          }
      })


      builder.addCase(saveCustResponseReturnnExtQuestionData.fulfilled, (state, { meta, payload, type }) => {
        //saving the successful answer to previous stored response
        
       //debugger;
        if(!meta.arg.NextQuestionSequence) {
          state.moveToLoginPage = true;
          return;
        }
       
        if(!isNaN(meta.arg.previousQuestionSequence) && 
        meta.arg.responses && 
        state.allQuestionsData && 
        state.allQuestionsData[meta.arg.title] && 
        state.allQuestionsData[meta.arg.title].length > 0 &&
        state.allQuestionsData[meta.arg.title][meta.arg.previousQuestionSequence]){
        
        const previousQuestion = state.allQuestionsData[meta.arg.title][meta.arg.previousQuestionSequence];
        if (previousQuestion) {
          previousQuestion.NextQuestionAnswerResponse = {
            "responses": meta.arg.responses
          };
        }
          //this is completely dependent on choice question
          updateQuestionColorChoicesInState(state,previousQuestion, previousQuestion.NextQuestionAnswerChoices.questions[0],meta.arg.responses[0].answers)

          //This is request for next questino but here we are setting the response of current question, we must limit the array length to it.
          //as there might be cache for next questions which we will need to remove. as answers are impacting other questions 
          state.allQuestionsData[meta.arg.title].length = meta.arg.previousQuestionSequence + 1;
          const titleArr = Object.keys(state.allQuestionsData);
          
          titleArr.map((eachTitle,index)=> {
            //here we are preserving all the values till the current question only what ever next questions or next sections questions will be removed
            //based on index of title we are checking if index is less than current meta.arg.title dont clear.
            if(index > titleArr.indexOf(meta.arg.title)){
              state.allQuestionsData[eachTitle] = []
            }

          })
        }
        if(meta.arg.NextQuestionSequence == 1){
          state.allQuestionsData = {} as any;
        }
        //if (meta.requestId === state.currentRequestId.requestId) {
        state.sectionQuestion = payload;
        state.roomId = payload.NextQuestionAnswerChoices.roomId;

        const answers = payload.NextQuestionAnswerResponse?.responses? payload.NextQuestionAnswerResponse.responses[0].answers : []
       
        const questions = payload.NextQuestionAnswerChoices?.questions || [];
        if (state.sectionQuestion?.NextQuestionAnswerChoices?.shortQuestion == "Room") {
          questions[0].choices?.map((choice: { choice: string; }) => {
            let matchedRooms = state.roomTypesData?.filter((roomType: { roomType: string; roomTypeDisplayName: string; }) => roomType.roomType == choice.choice || roomType.roomTypeDisplayName == choice.choice) || []; 
            choice.choice = matchedRooms[0]?.roomTypeDisplayName || choice.choice; 
            if ( matchedRooms[0]?.status !== "active") {
              choice.choice += " (coming soon)"
            }
          })
        }
        
        updateQuestionColorChoicesInState(state,state.sectionQuestion, payload.NextQuestionAnswerChoices.questions[0],answers);
        //saving the current question information in array to avoid extra calls for back or next
        let title = meta.arg.title;
        if(state.currentQuestionnaireInfo.firstQuestionNumber) {
          title = payload.NextQuestionAnswerChoices.title;
        }


        if(!state.allQuestionsData[title]) {
          state.allQuestionsData[title] = []
        }
        state.allQuestionsData[title].push(payload);
        try{
         // console.log(current(state.allQuestionsData));
        }catch(e){}
        state.loading = "success";
        state.currentRequestId = "";
        state.navigateToProjectGoal = false;
        
        let currentSection = state.sectionsInfoData[state.currentTab.index] as Section;
        state.currentQuestionnaireInfo.firstQuestionNumber = "";
        state.currentQuestionnaireInfo.nextSectionID = currentSection.sectionID;
        state.currentQuestionnaireInfo.questionID = payload.NextQuestionAnswerChoices.questionID;
        state.currentQuestionnaireInfo.shortQuestion = payload.NextQuestionAnswerChoices.shortQuestion;
        // state.currentQuestionnaireInfo.noOfQuestionsCount = state.sectionsInfoData[0].count
        // state.currentQuestionnaireInfo.questionSequence = 0
        // state.currentQuestionnaireInfo.sectionID = state.sectionsInfoData[0].sectionID;
        state.currentQuestionnaireInfo.navigateToNextSectionUsingCache = false;
        state.currentQuestionnaireInfo.previousQuestionSequence = state.currentQuestionnaireInfo.questionSequence;
        state.currentQuestionnaireInfo.questionSequence += 1;
        state.sectionQuestion.isLastQuestion = state.allQuestionsData[title].length === currentSection.count;
        const template =  state.sectionQuestion.NextQuestionAnswerChoices.questions[0].template
        state.isMinSelQuestion = parseInt(template.min_selections) > 0
        //if last question in section after success, just to make next section's  index and pick right currentSection
        if(state.currentQuestionnaireInfo.questionSequence > currentSection.count){
          //will trigger a call
          state.pickCurrentSectionDetailsToState = true;
        }
      })
      builder.addCase(saveCustResponseReturnnExtQuestionData.rejected, (state, { meta, payload, error }) => {
        
        if (meta.requestId === state.currentRequestId.requestId) {
            state.currentRequestId = meta;
            state.loading = "failed";
            state.moveToLoginPage = false;
            state.sectionQuestion = payload;
            state.error = error as string;
        }
    })
              
    }
});


export function GetOnboardinData() {
  const dispatch = useDispatch();
  const responseData = useSelector(welcomePageData);
  const sectionsInfoData  = useSelector((state: any ) => state.welcomePg.sectionsInfoData) as Array<Section>;
  const { 
    customerID, 
    roomId,
    profileData,
    projectID, 
    sectionQuestion,
    navigateToProjectGoal, 
    allQuestionsData, 
    currentQuestionnaireInfo, 
    pickCurrentSectionDetailsToState, 
    isMinSelQuestion,
    currentTab, 
    moveToLoginPage,
    styleProfileData,
    customerRoomLabelResponse,
    rooms3Ddata,
    roomTypesData,
    updatedHowDidWedo,
    updatedAvatarImage,
    updateStyleprofileResponse,
    customerFlowType,
    questionnarireSummaryInfo,
    styleSchemesData
  } = useSelector((state: any ) => state.welcomePg);

  const boundActionCreators = useMemo(
    () => bindActionCreators({ 
      resetRooms3DInfo: WelcomePageSlice.actions.resetRooms3DInfo,
      resetMoveToLoginPage: WelcomePageSlice.actions.resetMoveToLoginPage,
      postProfileData,
      postProjectGoal,
      getRoomTypesData,
      getSectionsData, 
      resetState, 
      saveCustResponseReturnnExtQuestionData, 
      setCurrentQuestion,
      saveCustomerStyleProfile,
      getStyleProfile,
      updateStyleprofile,
      getQuestionnaireSummmary,
      resetStyleProfileState,
      getCustomerRoomLabel,
      getRooms3DInfo,
      getStyleSchemes
    }, dispatch),
    [dispatch])

  return {
    ... boundActionCreators,
    responseData,
    customerID, 
    roomId,
    profileData,
    projectID, 
    sectionQuestion,
    navigateToProjectGoal, 
    allQuestionsData, 
    customerRoomLabelResponse,
    currentQuestionnaireInfo, 
    pickCurrentSectionDetailsToState, 
    isMinSelQuestion,
    currentTab, 
    moveToLoginPage,
    sectionsInfoData,
    styleProfileData,
    updatedHowDidWedo,
    updatedAvatarImage,
    updateStyleprofileResponse,
    questionnarireSummaryInfo,
    customerFlowType,
    rooms3Ddata,
    roomTypesData,
    styleSchemesData
  }  
}

export const { actions, reducer } = WelcomePageSlice;
export default reducer;